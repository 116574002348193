// TODO: Lots of non-null assertions here due to (likely) bad typing on SearchApi's end of things
/* eslint-disable @typescript-eslint/no-non-null-assertion */

// TODO: Would be nice to have a bit more knowledge about why the search service isn't
// returning results we can actually use without having to further filter them.
//
// The bulk of this file was adapted from:
// https://github.com/ModaOperandi/website/blob/50b33a480cb4640d0e8a3674c59bbbcf90c570cd/src/gui/shared/utils/search.ts

// TODO: None of the hrefs are correct here. We need a central set of functions for path generation

import { flatten, uniqBy, take } from 'ramda';
import { parameterize, compact, VERTICALS, Vertical, titleize } from '@moda/portal-stanchions';
import { QuickSearchQuery } from '../../generated/types';
import { hrefFor } from '../../routers';

type Trunkshow = NonNullable<
  NonNullable<NonNullable<QuickSearchQuery['autocomplete']>['trunkshows']>[number]
>;

type Designer = NonNullable<
  NonNullable<NonNullable<QuickSearchQuery['autocomplete']>['designers']>[number]
>;

type Category = NonNullable<
  NonNullable<NonNullable<QuickSearchQuery['autocomplete']>['categories']>[number]
>;

type Variant = NonNullable<
  NonNullable<
    NonNullable<NonNullable<NonNullable<QuickSearchQuery['search']>['variants']>['edges']>[number]
  >['node']
>;

export interface SearchResult {
  name: string;
  vertical?: string;
  value: string;
  href: string;
}

const sanitizeRegex = (string: string): string => string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

export interface SearchResultsSet {
  type: 'variant' | 'designer' | 'category' | 'trunkshow';
  title: string;
  results: SearchResult[];
}

const sanitizeStringForComparison = (string: string): string => parameterize(string, ' ');

const getVertical = (vertical: string) => vertical.replace('children', 'kids');

export const matchResult = (query: string, result: string) => {
  const sanitizedQuery = sanitizeStringForComparison(query);
  const sanitizedResult = sanitizeStringForComparison(result);

  const regex = new RegExp(
    ['(.*)', ...sanitizedQuery.split(' ').map(token => `(${sanitizeRegex(token)})(.*)`)].join('')
  );

  return sanitizedResult.match(regex);
};

const LIMIT = 5;
const limit = take(LIMIT);

export const deUnderscore = (string: string) => string.split('_').join(' ').trim();

export const prepareResults = (results: SearchResult[], query: string): SearchResult[] =>
  limit(
    uniqBy(({ name }) => name, results)
      .map(result => ({
        ...result,
        name: deUnderscore(result.name),
        value: deUnderscore(result.value)
      }))
      .filter(result => matchResult(query, result.name))
  );

export const parseVariants = (results: Variant[]): SearchResult[] =>
  results.map(result => ({
    name: result.name || '',
    value: result.slug,
    href: hrefFor.ProductDetailPage({
      vertical: VERTICALS[result.gender as Vertical],
      designerSlug: result.designerSlug || '',
      productSlug: result.slug,
      variantId: result.id
    })
  }));

export const parseTrunkshows = (results: (Trunkshow | null)[]): SearchResult[] =>
  compact(results).map(result => {
    const label = `${result!.name} ${result!.season}`;

    return {
      name: label,
      value: label,
      vertical: getVertical(result!.gender || ''),
      href: `/${result!.slug}`
    };
  });

export const parseDesigners = (results: (Designer | null)[]): SearchResult[] =>
  compact(results)
    .filter(result => result && result.slug)
    .map(result => ({
      name: result!.name!,
      value: result!.name!,
      href: hrefFor.DesignerPage({
        designerSlug: result!.slug!,
        ...(result?.genderSlug ? { vertical: result!.genderSlug! } : {})
      })
    }));

const isBeautyResult = (result: Category | null) =>
  result?.category === 'beauty' || result?.gender === 'beauty';

export const parseCategories = ({
  results,
  showBeauty
}: {
  results: (Category | null)[];
  showBeauty?: boolean;
}): SearchResult[] =>
  flatten(
    compact(results)
      .filter(result => showBeauty || !isBeautyResult(result))
      .map(result => {
        const { attribute, gender, category, subcategory, name } = result!;
        const categoryName = category === 'decor' ? 'home_decor' : category;
        const vertical = getVertical(gender || '');
        const attributeParams = attribute ? { attributes: attribute } : null;

        return [
          {
            name: titleize(`${categoryName} in ${gender}`),
            value: category!,
            vertical,
            href: hrefFor.ProductCategoryListingPage({
              category: category!.replace(/_/g, '-'),
              vertical,
              ...attributeParams
            })
          },
          {
            name: titleize(name!),
            vertical,
            value: subcategory!,
            href: hrefFor.ProductCategoryListingPage({
              category: category!.replace(/_/g, '-'),
              subcategory: subcategory!.replace(/_/g, '-'),
              vertical,
              ...attributeParams
            })
          }
        ];
      })
  );

export const parseSearchResults = ({
  query,
  data,
  showBeauty
}: {
  query: string;
  data?: QuickSearchQuery;
  showBeauty?: boolean;
}): {
  searchResults: SearchResult[];
  searchResultSets: SearchResultsSet[];
} => {
  if (!(data && data.autocomplete)) return { searchResults: [], searchResultSets: [] };

  const {
    autocomplete: { trunkshows, designers, categories }
  } = data;
  const variants = compact((data.search?.variants?.edges || []).map(edge => edge?.node));

  const variantResults = prepareResults(parseVariants(variants), query);
  const trunkshowResults = prepareResults(parseTrunkshows(trunkshows || []), query);
  const designerResults = prepareResults(parseDesigners(designers || []), query);
  const categoryResults = prepareResults(
    parseCategories({ results: categories || [], showBeauty }),
    query
  );
  const searchResults = [
    ...variantResults,
    ...trunkshowResults,
    ...designerResults,
    ...categoryResults
  ];

  return {
    searchResults,
    searchResultSets: [
      { type: 'variant', title: 'Product', results: variantResults },
      { type: 'trunkshow', title: 'Trunkshow', results: trunkshowResults },
      { type: 'designer', title: 'Designer', results: designerResults },
      { type: 'category', title: 'Category', results: categoryResults }
    ]
  };
};
