import { useLocation } from 'react-router-dom';
import { useQueryParams } from '@moda/portal-stanchions';
import { useDesktopNavigation } from '../../hooks/useNavigation';
import { useCurrentRouteParams } from '../../hooks/useCurrentRouteParams';

export const useDesktopNavigationV2 = () => {
  const { pathname } = useLocation();
  const { queryParams } = useQueryParams();
  const params = useCurrentRouteParams<{ vertical?: string }>();
  const {
    navigation: { data, loading, error }
  } = useDesktopNavigation();

  const verticalSlug = params?.vertical ?? queryParams.vertical;

  const selectedVerticalData =
    data?.verticals.find(
      vertical => pathname === vertical.href || vertical.href.replace('/', '') === verticalSlug
    ) ??
    data?.verticals.find(vertical => vertical.href === '/') ??
    data?.verticals[0];

  return { data, loading, error, selectedVerticalData, verticals: data?.verticals };
};
