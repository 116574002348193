import React, { useState, useEffect, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useFeatureFlag } from '@moda/portal-stanchions';
import { Modal } from '../Modal';
import { MobileSiteNavMenu } from './MobileSiteNavMenu';

import './MobileSiteNavOverlay.scss';

enum Mode {
  Resting,
  Mounted
}

interface Props {
  onClose(): void;
  navRef: HTMLDivElement | null;
}

export const MobileSiteNavOverlay: React.FC<Props> = ({ onClose, navRef }) => {
  const [mode, setMode] = useState(Mode.Resting);

  const refreshedMobileSiteNavEnabled = useFeatureFlag('refreshed-mobile-site-nav', [
    { default: false },
    { if: true, then: true }
  ]);

  const top = useMemo(() => {
    if (!refreshedMobileSiteNavEnabled) return undefined;

    const siteNav = document.querySelector('.SiteNav');

    if (siteNav) {
      const { top, height } = siteNav.getBoundingClientRect();

      return top + height;
    }
  }, [refreshedMobileSiteNavEnabled]);

  useEffect(() => setMode(Mode.Mounted), []);

  const handlePreventClose = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => event.stopPropagation(),
    []
  );

  return (
    <Modal
      className={classNames('MobileSiteNavOverlay', {
        'MobileSiteNavOverlay--active': mode === Mode.Mounted,
        'MobileSiteNavOverlay--active-refreshed':
          mode === Mode.Mounted && refreshedMobileSiteNavEnabled
      })}
      tabIndex={0}
      role="button"
      onClose={onClose}
      style={{ top }}
      shards={navRef ? [navRef] : undefined}
    >
      <MobileSiteNavMenu onClick={handlePreventClose} onClose={onClose} />
    </Modal>
  );
};
