import { gql, TypedDocumentNode } from '@apollo/client';
import React, { useEffect, useReducer, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import ExitIcon from '@moda/icons/exit-20';
import FavoriteOutlineIcon from '@moda/icons/favorite-outline-16';
import { Clickable, Loading } from '@moda/om';
import { useFeatureFlag } from '@moda/portal-stanchions';
import { MobileSiteNavMenuQuery, MobileSiteNavMenuQueryVariables } from '../../generated/types';
import { useSkippableQuery } from '../../hooks/useSkippableQuery';
import { useUser } from '../../hooks/useUser';
import { throwQueryError } from '../../lib/apollo/throwQueryError';
import { useCountry } from '../CountrySelector';
import { MobileSiteNavOption } from './MobileSiteNavOption';
import { MobileSiteNavCountrySelector } from './MobileSiteNavCountrySelector';
import { RefreshedMobileSiteNavMenu } from './RefreshedMobileSiteNavMenu';
import {
  MobileSiteNavMenuPane,
  MOBILE_SITE_NAV_MENU_PANE_FRAGMENT,
  Options
} from './MobileSiteNavMenuPane';

import './MobileSiteNavMenu.scss';

enum Mode {
  Resting,
  Mounted
}

enum Pane {
  Menu,
  Country
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onClose(): void;
}

export type SelectOptions = { label: string | null; options: Options };

interface State {
  mode: Mode;
  pane: Pane;
  vertical: 'women';
  cursor: number;
  options: SelectOptions[];
}

export const MOBILE_SITE_NAV_MENU_QUERY: TypedDocumentNode<
  MobileSiteNavMenuQuery,
  MobileSiteNavMenuQueryVariables
> = gql`
  query MobileSiteNavMenuQuery($vertical: String!) {
    displayPage(slug: $vertical, gender: $vertical) {
      ...MobileSiteNavMenuPaneFragment
    }
  }
  ${MOBILE_SITE_NAV_MENU_PANE_FRAGMENT}
`;

type Action =
  | { type: 'MOUNT' }
  | { type: 'RESET_OPTIONS'; payload: SelectOptions }
  | { type: 'PUSH_OPTIONS'; payload: SelectOptions }
  | { type: 'SHIFT_OPTIONS' }
  | { type: 'OPEN_PANE'; payload: { pane: Pane } };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'MOUNT':
      return { ...state, mode: Mode.Mounted };
    case 'RESET_OPTIONS':
      return { ...state, cursor: 0, options: [action.payload] };
    case 'PUSH_OPTIONS':
      return { ...state, cursor: state.cursor + 1, options: [...state.options, action.payload] };
    case 'SHIFT_OPTIONS':
      return {
        ...state,
        cursor: state.cursor - 1,
        options: state.options.slice(0, state.options.length - 1)
      };
    case 'OPEN_PANE':
      return { ...state, pane: action.payload.pane };
  }
};

// TODO: Replace with actual solution for animation once we refine
const clearCallStackForAnimationIn = (handler: TimerHandler) => setTimeout(handler, 2);

export const MobileSiteNavMenu: React.FC<Props> = ({ onClose, ...rest }) => {
  const { country } = useCountry();
  const { user } = useUser();
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, {
    mode: Mode.Resting,
    pane: Pane.Menu,
    vertical: 'women',
    cursor: 0,
    options: []
  });

  const menuRef = useRef<HTMLDivElement | null>(null);

  const refreshedMobileSiteNavEnabled = useFeatureFlag('refreshed-mobile-site-nav', [
    { default: false },
    { if: true, then: true }
  ]);

  const { data, error, loading } = useSkippableQuery(MOBILE_SITE_NAV_MENU_QUERY, {
    skip: state.mode === Mode.Resting,
    variables: { vertical: state.vertical }
  });

  const handleScrollTo = useCallback(
    (top: number) => {
      menuRef?.current?.scrollTo({ top, behavior: 'smooth' });
    },
    [menuRef]
  );

  const getCurrentVerticalScroll = useCallback(() => menuRef?.current?.scrollTop, []);

  useEffect(() => {
    data?.displayPage?.options &&
      dispatch({
        type: 'RESET_OPTIONS',
        payload: { label: null, options: data.displayPage.options }
      });
  }, [data]);

  useEffect(() => {
    clearCallStackForAnimationIn(() => dispatch({ type: 'MOUNT' }));
  }, []);

  const handleSelectOption = useCallback(
    ({ label, options }: SelectOptions) =>
      dispatch({ type: 'PUSH_OPTIONS', payload: { label, options } }),
    []
  );

  const handleBack = useCallback(() => dispatch({ type: 'SHIFT_OPTIONS' }), []);

  const handleOpenPane = useCallback(
    (pane: Pane) => () => dispatch({ type: 'OPEN_PANE', payload: { pane } }),
    []
  );

  const handleOpenCountrySelector = handleOpenPane(Pane.Country);
  const handleOpenMenu = handleOpenPane(Pane.Menu);

  if (error) throwQueryError(error);

  const activeOptions = state.options[state.cursor];

  return (
    <div
      className={classNames('MobileSiteNavMenu', {
        'MobileSiteNavMenu--active': state.mode !== Mode.Resting
      })}
      {...rest}
    >
      {!refreshedMobileSiteNavEnabled && (
        <div className="MobileSiteNavMenu__menu">
          {state.pane === Pane.Country ? (
            <MobileSiteNavCountrySelector onClose={handleOpenMenu} />
          ) : loading ? (
            <Loading />
          ) : (
            <>
              {state.cursor === 0 && (
                <div className="MobileSiteNavMenu__option-homepage">
                  <MobileSiteNavOption onClick={onClose} href={`/${state.vertical}`}>
                    Homepage
                  </MobileSiteNavOption>
                </div>
              )}

              <MobileSiteNavMenuPane
                options={activeOptions}
                onSelect={handleSelectOption}
                onBack={handleBack}
                onClose={onClose}
              />

              {state.cursor === 0 && (
                <>
                  <hr className="MobileSiteNavMenu__divider" />

                  {!user.isLoggedIn ? (
                    <MobileSiteNavOption
                      onClick={onClose}
                      href={`/register?redirectTo=${location.pathname}`}
                    >
                      Sign In/Register
                    </MobileSiteNavOption>
                  ) : (
                    <MobileSiteNavOption onClick={onClose} href="/account">
                      My Account
                    </MobileSiteNavOption>
                  )}

                  <MobileSiteNavOption onClick={handleOpenCountrySelector}>
                    My Currency ({country.alpha3Code})
                  </MobileSiteNavOption>

                  <MobileSiteNavOption onClick={onClose} href="/favorites">
                    My Favorites <FavoriteOutlineIcon />
                  </MobileSiteNavOption>

                  {user.isLoggedIn && (
                    <MobileSiteNavOption onClick={onClose} href="/logout">
                      Sign Out
                    </MobileSiteNavOption>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}

      {refreshedMobileSiteNavEnabled && (
        <div className="MobileSiteNavMenu__menu MobileSiteNavMenu__menu--refreshed" ref={menuRef}>
          <RefreshedMobileSiteNavMenu
            onClose={onClose}
            verticallyScrollTo={handleScrollTo}
            getCurrentVerticalScroll={getCurrentVerticalScroll}
          />
        </div>
      )}

      <Clickable
        className={classNames('MobileSiteNavMenu__close', {
          'MobileSiteNavMenu__close--refreshed': refreshedMobileSiteNavEnabled
        })}
        onClick={onClose}
      >
        {!refreshedMobileSiteNavEnabled && <ExitIcon />}
      </Clickable>
    </div>
  );
};
