import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ResponsiveImage } from '../../ResponsiveImage';
import { EditorialNavigatorUnitFragment } from '../../../generated/types';
import { DesktopNavigationV2EditorialColumnText } from './DesktopNavigationV2EditorialColumnText';

import './DesktopNavigationV2EditorialColumn.scss';

interface Props {
  className?: string;
  content: EditorialNavigatorUnitFragment;
}

export const DesktopNavigationV2EditorialColumn: React.FC<Props> = ({
  className,
  content: { imageSrc, imageAlt, href, headline, anchorText }
}) => (
  <div className={classNames('DesktopNavigationV2EditorialColumn', className)}>
    <Link className="DesktopNavigationV2EditorialColumn__anchor" to={href || ''}>
      <DesktopNavigationV2EditorialColumnText text={headline} />

      {imageSrc && (
        <ResponsiveImage
          src={imageSrc}
          maxWidth={160}
          maxHeight={260}
          alt={imageAlt || 'Editorial Image'}
        />
      )}

      {anchorText && <DesktopNavigationV2EditorialColumnText text={anchorText} />}
    </Link>
  </div>
);
