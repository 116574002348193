import React, { useState, useCallback, useRef } from 'react';
import LogoHorizontalIcon from '@moda/icons/logo-horizontal-189-x-12';
import HamburgerIcon from '@moda/icons/hamburger-24';
import ExitIcon from '@moda/icons/exit-24';
import FavoriteOutlineIcon from '@moda/icons/favorite-outline-20';
import { Clickable } from '@moda/om';
import SearchIcon from '@moda/icons/search-20';
import { useFeatureFlag } from '@moda/portal-stanchions';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { QuickSearch } from '../QuickSearch';
import { MiniCart } from '../MiniCart';
import { hrefFor } from '../../routers';
import { useEnableClubModaTreatment } from '../../hooks/useEnableClubModaTreatment';
import { MobileSiteNavOverlay } from './MobileSiteNavOverlay';

import './MobileSiteNav.scss';

export const MobileSiteNav: React.FC = () => {
  const [isOpen, setOpen] = useState(false);
  const contentsRef = useRef<HTMLDivElement | null>(null);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const history = useHistory();
  const refreshedMobileSiteNavEnabled = useFeatureFlag('refreshed-mobile-site-nav', [
    { default: false },
    { if: true, then: true }
  ]);

  const handleFavoriteIconClick = useCallback(() => {
    setOpen(false);
    history.push(hrefFor.FavoritesPage());
  }, [history]);

  const handleOpen = useCallback(() => setOpen(true), []);

  const handleClose = useCallback(() => setOpen(false), []);

  const handleSearchIconClick = useCallback(() => {
    if (refreshedMobileSiteNavEnabled && isOpen) {
      handleClose();
    }

    setShowSearchInput(prevValue => !prevValue);

    handleClose();
  }, [handleClose, isOpen, refreshedMobileSiteNavEnabled]);

  const handleLogoClick = useCallback(() => {
    handleClose();
    history.push(hrefFor.HomePage());
  }, [handleClose, history]);

  const enableClubModaTreatment = useEnableClubModaTreatment();

  return (
    <>
      {isOpen && <MobileSiteNavOverlay onClose={handleClose} navRef={contentsRef.current} />}

      <nav className="MobileSiteNav" data-testid="mobileNavItems">
        <div className="MobileSiteNav__contents" ref={contentsRef}>
          <div className="MobileSiteNav__rail">
            <div className="MobileSiteNav__actions">
              <Clickable
                aria-label="Open Navigation Menu"
                className="MobileSiteNav__hamburger"
                onClick={isOpen ? handleClose : handleOpen}
              >
                {refreshedMobileSiteNavEnabled && (
                  <>
                    {!isOpen && <HamburgerIcon />}

                    {isOpen && <ExitIcon />}
                  </>
                )}

                {!refreshedMobileSiteNavEnabled && <HamburgerIcon />}
              </Clickable>

              <SearchIcon className="MobileSiteNav__search-icon" onClick={handleSearchIconClick} />
            </div>

            <Clickable
              aria-label="Moda Operandi Home Page"
              className={classNames('MobileSiteNav__logo', {
                'MobileSiteNav__logo--club': enableClubModaTreatment
              })}
              data-testid="mobileLogo"
              onClick={handleLogoClick}
            >
              <LogoHorizontalIcon height="10px" />
            </Clickable>

            <div className="MobileSiteNav__actions">
              <Clickable aria-label="My Favorites" onClick={handleFavoriteIconClick}>
                <FavoriteOutlineIcon className="MobileSiteNav__favorite" />
              </Clickable>

              <MiniCart screen="mobile" />
            </div>
          </div>

          {showSearchInput && (
            <QuickSearch
              className="MobileSiteNav__search"
              placeholder="What are you looking for?"
              mobile
              onSelect={() => setShowSearchInput(false)}
            />
          )}
        </div>
      </nav>
    </>
  );
};
