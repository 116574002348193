import { useReducer, useRef, useEffect, useCallback } from 'react';

type State = {
  cursor: number;
  transitioning: boolean;
};

type Action = { type: 'START_TRANSITION' } | { type: 'END_TRANSITION' } | { type: 'RESET' };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'START_TRANSITION':
      return {
        cursor: state.cursor,
        transitioning: true
      };

    case 'END_TRANSITION':
      return {
        cursor: state.cursor + 1,
        transitioning: false
      };

    case 'RESET':
      return {
        cursor: 0,
        transitioning: false
      };

    default:
      return state;
  }
};

export const useDuration = ({
  duration,
  transition,
  active = true
}: {
  duration: number;
  transition: number;
  active?: boolean;
}) => {
  const [{ cursor, transitioning }, dispatch] = useReducer(reducer, {
    cursor: 0,
    transitioning: false
  });
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const startInterval = useRef<ReturnType<typeof setInterval>>();
  const endInterval = useRef<ReturnType<typeof setInterval>>();

  const clearIntervals = useCallback(() => {
    timeout.current && clearTimeout(timeout.current);
    startInterval.current && clearInterval(startInterval.current);
    endInterval.current && clearInterval(endInterval.current);
  }, []);

  useEffect(() => {
    if (active) {
      dispatch({ type: 'RESET' });
      timeout.current = setTimeout(() => {
        dispatch({ type: 'START_TRANSITION' });
        startInterval.current = setInterval(
          () => dispatch({ type: 'START_TRANSITION' }),
          duration + transition
        );
      }, duration);
      endInterval.current = setInterval(
        () => dispatch({ type: 'END_TRANSITION' }),
        duration + transition
      );
    }

    if (!active) {
      clearIntervals();
      dispatch({ type: 'RESET' });
    }

    return clearIntervals;
  }, [active, duration, transition, clearIntervals]);

  return { cursor, transitioning };
};
