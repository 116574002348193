import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Button, Clickable, ControlLink, Input, Overlay, Stack, Text } from '@moda/om';
import LogoHorizontalIcon from '@moda/icons/logo-horizontal-189-x-12';
import SocialPhoneIcon from '@moda/icons/social-phone-16';
import ChevronRightIcon from '@moda/icons/chevron-right-20';
import MailIcon from '@moda/icons/mail-16';
import ExitIcon from '@moda/icons/exit-16';
import { useFeatureFlag } from '@moda/portal-stanchions';
import { hrefFor } from '../../routers';
import { PhoneNumberTermsAndConditions } from '../PhoneNumberTermsAndConditions';
import { useEmailSignup } from './useEmailSignup';

import './EmailSignup.scss';

export const EmailSignup: React.FC = () => {
  const {
    handleClose,
    showModal,
    phoneNumberEnabled,
    email,
    handleSmsSubmit,
    handleEmailSubmit,
    error,
    setEmail,
    setPhone,
    phone,
    mailValidationError,
    phoneNumberValidationError,
    isEmailStep,
    isSmsStep,
    isConfirmationStep,
    isExistingSubscription,
    isModalVisible
  } = useEmailSignup();

  const show = showModal && isModalVisible;

  // The feature flag is a number, but we'll want a string to display.
  const welcomeOffer = useFeatureFlag('configuration-set-welcome-offer', [
    { default: '15' },
    { getFromTreatment: treatment => String(treatment) ?? '15' }
  ]);

  return (
    <Overlay show={show} className="EmailSignup">
      <div className="EmailSignup__wrapper">
        <div
          className={classNames('EmailSignup__image-wrapper', {
            'EmailSignup__image-wrapper--hide': isSmsStep
          })}
        >
          <img
            className="EmailSignup__image"
            width="100%"
            src="/dist/public/modal_bg.jpg"
            alt="signup modal"
          />
        </div>
        <div className="EmailSignup__container">
          <div className="EmailSignup__right">
            <Clickable className="EmailSignup__close" aria-label="Close" onClick={handleClose}>
              <ExitIcon />
            </Clickable>
            <div className="EmailSignup__logo" data-testid="logo">
              <Link className="EmailSignup__homelink" aria-label="Moda Operandi Modal" to="/">
                <LogoHorizontalIcon className="EmailSignup__modalogo" width="80%" height="80%" />
              </Link>
            </div>
            <Stack
              className={classNames('EmailSignup__content', {
                'EmailSignup__content--center': isSmsStep
              })}
              space={4}
              justifyContent="flex-end"
            >
              {((!isExistingSubscription && isEmailStep) || isSmsStep) && (
                <Text className="EmailSignup__title" family="serif" treatment="h3">
                  Get {welcomeOffer}% off
                </Text>
              )}

              {isExistingSubscription && isEmailStep && (
                <Text className="EmailSignup__title" family="serif" treatment="h3">
                  You're on the list.
                </Text>
              )}

              {!isExistingSubscription && isEmailStep && (
                <Stack className="EmailSignup__bodycontainer" space={2}>
                  <Text className="EmailSignup__body" treatment="body1">
                    your first order when you enroll in email {phoneNumberEnabled && '& text'}{' '}
                    notifications.
                  </Text>
                </Stack>
              )}

              {isSmsStep && (
                <Stack className="EmailSignup__heading" space={2}>
                  <Text treatment="h6">You're almost there</Text>
                  <PhoneNumberTermsAndConditions color="coal" />
                </Stack>
              )}

              {isEmailStep && (
                <form className="EmailSignup__form" onSubmit={handleEmailSubmit}>
                  <div className="EmailSignup__inputform">
                    <Input
                      className="EmailSignup__input"
                      name="email"
                      placeholder="Email"
                      type="email"
                      error={mailValidationError}
                      required
                      value={email}
                      onChange={setEmail}
                    />
                    <Clickable
                      aria-label="Submit email for our newsletter"
                      className="EmailSignup__submitbutton"
                      type="submit"
                    >
                      <ChevronRightIcon />
                    </Clickable>
                  </div>

                  {error && (
                    <div
                      className={classNames('EmailSignup__status', {
                        'EmailSignup__status--error': error
                      })}
                    >
                      Unable to subscribe you at this time.
                    </div>
                  )}

                  <Button className="EmailSignup__submit" type="submit" role="button">
                    SIGN UP
                  </Button>

                  <Clickable
                    className="EmailSignup__dismiss"
                    aria-label="Close"
                    onClick={handleClose}
                  >
                    <Text treatment="body1" color="ink">
                      No Thanks
                    </Text>
                  </Clickable>
                </form>
              )}

              {isSmsStep && (
                <form
                  className="EmailSignup__form EmailSignup__form--second_step"
                  onSubmit={handleSmsSubmit}
                >
                  <div className="EmailSignup__inputform">
                    <Input
                      className="EmailSignup__input"
                      name="phone"
                      placeholder="Mobile"
                      type="phone"
                      required
                      error={phoneNumberValidationError}
                      value={phone}
                      onChange={setPhone}
                    />
                    <Clickable
                      aria-label="Submit email for our newsletter"
                      className="EmailSignup__submitbutton"
                      type="submit"
                    >
                      Sign up for text
                    </Clickable>
                  </div>

                  {error && (
                    <div
                      className={classNames('EmailSignup__status', {
                        'EmailSignup__status--error': error
                      })}
                    >
                      Unable to subscribe you at this time.
                    </div>
                  )}

                  <Button className="EmailSignup__submit" type="submit" role="button">
                    SIGN UP FOR TEXTS
                  </Button>
                </form>
              )}

              {isConfirmationStep && (
                <div className="EmailSignup__confirmation">
                  {phoneNumberEnabled ? (
                    <SocialPhoneIcon
                      color="goldenrod"
                      width="36px"
                      height="36px"
                      className="EmailSignup__icon"
                    />
                  ) : (
                    <MailIcon
                      color="goldenrod"
                      width="36px"
                      height="36px"
                      className="EmailSignup__icon"
                    />
                  )}
                  <Text className="EmailSignup__finalstep" family="serif" treatment="h3">
                    Check your {phoneNumberEnabled ? 'texts' : 'inbox'}
                  </Text>
                  <Text>
                    {phoneNumberEnabled
                      ? 'Reply “Y” to confirm your subscription. We look forward to sharing early access on products, exclusive offers, and more.'
                      : `A confirmation email will be sent to you shortly with your ${welcomeOffer}% off code.`}
                  </Text>
                </div>
              )}

              <Stack space={2} className="EmailSignup__footer">
                <Text className="EmailSignup__terms" treatment="body2" color="cement">
                  <ControlLink
                    to={hrefFor.TermsAndConditionsPage()}
                    className="EmailSignup__footer-link"
                    underlined
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Text treatment="body2" color="cement">
                      Terms
                    </Text>
                  </ControlLink>{' '}
                  &amp;{' '}
                  <ControlLink
                    to={hrefFor.TermsAndConditionsPage()}
                    className="EmailSignup__footer-link"
                    underlined
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Text treatment="body2" color="cement">
                      Conditions
                    </Text>
                  </ControlLink>{' '}
                  apply
                </Text>
              </Stack>
            </Stack>
          </div>
        </div>
      </div>
    </Overlay>
  );
};
