import React, { useState, useCallback, useRef, useEffect } from 'react';
import LogoHorizontalIcon from '@moda/icons/logo-horizontal-189-x-12';
import HamburgerIcon from '@moda/icons/hamburger-24';
import ExitIcon from '@moda/icons/exit-24';
import FavoriteOutlineIcon from '@moda/icons/favorite-outline-20';
import { Clickable } from '@moda/om';
import SearchIcon from '@moda/icons/search-20';
import { useHistory } from 'react-router-dom';
import { QuickSearch } from '../QuickSearch';
import { MiniCart } from '../MiniCart';
import { hrefFor } from '../../routers';
import { MobileNavV2Overlay } from './MobileNavV2Overlay';

import './MobileNavV2.scss';

export const MobileNavV2: React.FC = () => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const history = useHistory();
  const [drawerTop, setDrawerTop] = useState<undefined | number>(undefined);

  const calculateDrawerTop = useCallback(() => {
    if (!ref.current) return;

    const { current: component } = ref;

    if (component) {
      const { top, height } = component.getBoundingClientRect();

      return top + height;
    }
  }, []);

  useEffect(() => setDrawerTop(calculateDrawerTop()), [calculateDrawerTop]);

  const handleFavoriteIconClick = useCallback(() => {
    setOpen(false);

    history.push(hrefFor.FavoritesPage());
  }, [history]);

  const handleOpen = useCallback(() => setOpen(true), []);

  const handleClose = useCallback(() => setOpen(false), []);

  const handleSearchIconClick = useCallback(() => {
    setShowSearchInput(prevValue => !prevValue);
  }, []);

  useEffect(() => setDrawerTop(calculateDrawerTop()), [showSearchInput, calculateDrawerTop]);

  const handleLogoClick = useCallback(() => {
    handleClose();
    history.push(hrefFor.HomePage());
  }, [handleClose, history]);

  return (
    <>
      {isOpen && <MobileNavV2Overlay onClose={handleClose} navRef={ref.current} top={drawerTop} />}

      <nav className="MobileNavV2" data-testid="mobileNavItems" ref={ref}>
        <div className="MobileNavV2__contents">
          <div className="MobileNavV2__rail">
            <div className="MobileNavV2__actions">
              <Clickable
                aria-label="Open Navigation Menu"
                className="MobileNavV2__hamburger"
                onClick={isOpen ? handleClose : handleOpen}
              >
                {!isOpen && <HamburgerIcon />}

                {isOpen && <ExitIcon />}
              </Clickable>

              <SearchIcon className="MobileNavV2__search-icon" onClick={handleSearchIconClick} />
            </div>

            <Clickable
              aria-label="Moda Operandi Home Page"
              className="MobileNavV2__logo"
              data-testid="mobileLogo"
              onClick={handleLogoClick}
            >
              <LogoHorizontalIcon height="10px" />
            </Clickable>

            <div className="MobileNavV2__actions">
              <Clickable aria-label="My Favorites" onClick={handleFavoriteIconClick}>
                <FavoriteOutlineIcon className="MobileNavV2__favorite" />
              </Clickable>

              <MiniCart screen="mobile" />
            </div>
          </div>

          {showSearchInput && (
            <QuickSearch
              className="MobileNavV2__search"
              placeholder="What are you looking for?"
              mobile
              onSelect={() => setShowSearchInput(false)}
            />
          )}
        </div>
      </nav>
    </>
  );
};
