import { useFeatureFlag } from '@moda/portal-stanchions';
import { NavigatorPlatform } from '../../generated/types';
import { useNavigation } from './useNavigation';

export const useDesktopNavigation = () => {
  const isDesktopSiteNavV2Enabled = useFeatureFlag('desktop-nav-v2', [
    { default: false },
    { if: true, then: true }
  ]);

  const navigation = useNavigation(NavigatorPlatform.DESKTOP, !isDesktopSiteNavV2Enabled);

  return { navigation, isDesktopSiteNavV2Enabled };
};
