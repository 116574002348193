import React from 'react';

import { NavigatorVerticalItemFragment } from '../../../generated/types';
import { DesktopNavigationV2ListColumnV2 } from '../DesktopNavigationV2ListColumnV2';
import { DesktopNavigationV2EditorialColumn } from '../DesktopNavigationV2EditorialColumn';

import './DesktopNavigationV2Pulldown.scss';

type Props = {
  content: NavigatorVerticalItemFragment;
};

export const DesktopNavigationV2Pulldown: React.FC<Props> = ({ content }) => (
  <div className="DesktopNavigationV2Pulldown">
    {content.units.map((unit, index) => (
      <React.Fragment key={index}>
        {unit.__typename === 'ListNavigatorUnit' && (
          <DesktopNavigationV2ListColumnV2
            className="DesktopNavigationV2Pulldown__column"
            parentLabel={content.name}
            content={unit}
          />
        )}

        {unit.__typename === 'EditorialNavigatorUnit' && (
          <DesktopNavigationV2EditorialColumn
            className="DesktopNavigationV2Pulldown__column"
            content={unit}
          />
        )}
      </React.Fragment>
    ))}
  </div>
);
