import {
  NavigationQuery,
  NavigationQueryVariables,
  NavigatorPlatform
} from '../../generated/types';
import { useSkippableQuery } from '../useSkippableQuery';
import { NAVIGATION_QUERY } from './useNavigation.gql';

export const useNavigation = (platform: NavigatorPlatform, skip?: boolean) => {
  const { data, loading, error } = useSkippableQuery<NavigationQuery, NavigationQueryVariables>(
    NAVIGATION_QUERY,
    {
      variables: { platform },
      skip
    }
  );

  return { data: data?.navigator, loading, error };
};
