import { Clickable, Stack, Text } from '@moda/om';
import React from 'react';
import { EditorialNavigatorUnitFragment } from '../../../generated/types';

import './MobileNavV2EditorialsSection.scss';

interface Props {
  editorials: EditorialNavigatorUnitFragment[];
}

const sanitizeUrl = (url: string | null) => {
  if (!url) return undefined;

  try {
    // we can log this -- here the url looks like https://modaoperandi.com/clothing/etc and it should be strict pathname
    return new URL(url).pathname;
  } catch (error) {
    return url;
  }
};

export const MobileNavV2EditorialsSection: React.FC<Props> = ({ editorials }) => (
  <Stack className="MobileNavV2EditorialsSection" direction="horizontal" space={3}>
    {editorials.map(({ anchorText, imageSrc, imageAlt, href: url, headline }, index) => (
      <Stack key={index} space={2}>
        {headline && <Text>{headline}</Text>}

        <Clickable href={sanitizeUrl(url)}>
          <img
            alt={imageAlt || 'Editorial Image'}
            className="MobileNavV2EditorialsSection__image"
            key={`${imageSrc} -- ${imageAlt} -- ${index}`}
            src={imageSrc}
          />
        </Clickable>

        {anchorText && <Text>{anchorText}</Text>}
      </Stack>
    ))}
  </Stack>
);
