import React from 'react';
import { Breakpoint } from '@moda/om';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { hrefFor } from '../../routers';
import { useDesktopNavigation } from '../../hooks/useNavigation';
import { SiteFooterLargeDesktop } from './SiteFooterLargeDesktop';
import { SiteFooterCheckout } from './SiteFooterCheckout';
import { SiteFooterMobile } from './SiteFooterMobile';
import { SiteFooterDesktop } from './SiteFooterDesktop';

import './SiteFooter.scss';

export type Props = React.HTMLAttributes<HTMLDivElement>;

export const SiteFooter: React.FC<Props> = ({ ...rest }) => {
  const { pathname } = useLocation();
  const { isDesktopSiteNavV2Enabled } = useDesktopNavigation();

  if (pathname === hrefFor.CheckoutPage()) {
    return (
      <footer
        className={classNames('SiteFooter', { 'SiteFooter--refresh': isDesktopSiteNavV2Enabled })}
      >
        <SiteFooterCheckout />
      </footer>
    );
  }

  return (
    <footer
      className={classNames('SiteFooter', { 'SiteFooter--refresh': isDesktopSiteNavV2Enabled })}
      {...rest}
    >
      <Breakpoint lt="md">
        <SiteFooterMobile />
      </Breakpoint>

      <Breakpoint gt="md">
        <Breakpoint lt="lg">
          <SiteFooterDesktop />
        </Breakpoint>

        <Breakpoint gt="lg">
          <SiteFooterLargeDesktop />
        </Breakpoint>
      </Breakpoint>
    </footer>
  );
};
