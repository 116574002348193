import React from 'react';
import { Text } from '@moda/om';
import classNames from 'classnames';
import { NavigatorVerticalFragment } from '../../../generated/types';
import { tc } from '../../../lib/trackingContext';
import { useDesktopNavigationV2 } from '../useDesktopNavigationV2';
import { Link } from '../../Link';

import './DesktopNavigationV2Vertical.scss';

type Props = {
  vertical: NavigatorVerticalFragment;
};

export const DesktopNavigationV2Vertical: React.FC<Props> = ({ vertical }) => {
  const { selectedVerticalData } = useDesktopNavigationV2();

  const isSelected = selectedVerticalData?.id === vertical.id;

  return (
    <Link
      className={classNames('DesktopNavigationV2Vertical', {
        'DesktopNavigationV2Vertical--selected': isSelected,
        'DesktopNavigationV2Vertical--unselected': !isSelected
      })}
      data-testid="DesktopNavigationV2Vertical"
      to={vertical.href}
      onClick={event =>
        tc.track('Navigation Item Clicked', {
          addToTrackingEvent: {
            url: vertical.href,
            text: vertical.title,
            label: vertical.title,
            hierarchy: vertical.title,
            coordinateX: event?.pageX || 0,
            coordinateY: event?.pageY || 0
          }
        })
      }
    >
      <Text
        className="DesktopNavigationV2Vertical__title"
        color="klein-blue"
        data-testid="DesktopNavigationV2Vertical__title"
        treatment={isSelected || vertical.style.bold ? 'bold1' : undefined}
        style={vertical.style.color ? { color: vertical.style.color } : undefined}
      >
        {vertical.title}
      </Text>
    </Link>
  );
};
