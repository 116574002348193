import { gql } from '@apollo/client';

const LIST_NAVIGATOR_UNIT_SUBCATEGORY_FRAGMENT = gql`
  fragment ListNavigatorUnitSubcategoryFragment on NavigatorUnitListItem {
    id
    title
    href
    type
  }
`;

const LIST_NAVIGATOR_UNIT_FRAGMENT = gql`
  fragment ListNavigatorUnitFragment on ListNavigatorUnit {
    header
    subcategories: list {
      ...ListNavigatorUnitSubcategoryFragment
    }
  }
  ${LIST_NAVIGATOR_UNIT_SUBCATEGORY_FRAGMENT}
`;

const EDITORIAL_NAVIGATOR_UNIT_FRAGMENT = gql`
  fragment EditorialNavigatorUnitFragment on EditorialNavigatorUnit {
    anchorText
    headline
    href
    imageSrc: image_source
    imageAlt: image_alt
  }
`;

const NAVIGATOR_VERTICAL_ITEM_FRAGMENT = gql`
  fragment NavigatorVerticalItemFragment on NavigatorItem {
    id
    name
    href
    style {
      color
      bold
    }
    units {
      ...ListNavigatorUnitFragment
      ...EditorialNavigatorUnitFragment
    }
  }
  ${LIST_NAVIGATOR_UNIT_FRAGMENT}
  ${EDITORIAL_NAVIGATOR_UNIT_FRAGMENT}
`;

const NAVIGATOR_VERTICAL_FRAGMENT = gql`
  fragment NavigatorVerticalFragment on NavigatorVertical {
    id
    items {
      ...NavigatorVerticalItemFragment
    }
    title
    href
    style {
      color
      bold
    }
  }
  ${NAVIGATOR_VERTICAL_ITEM_FRAGMENT}
`;

const NAVIGATOR_FRAGMENT = gql`
  fragment NavigatorFragment on Navigator {
    id
    verticals {
      ...NavigatorVerticalFragment
    }
  }
  ${NAVIGATOR_VERTICAL_FRAGMENT}
`;

export const NAVIGATION_QUERY = gql`
  query NavigationQuery($platform: NavigatorPlatform!) {
    navigator(platform: $platform) {
      ...NavigatorFragment
    }
  }
  ${NAVIGATOR_FRAGMENT}
`;
