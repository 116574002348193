import { generatePath } from 'react-router-dom';
import { parameterize } from '@moda/portal-stanchions';

export type Params = { [param: string]: string };
export type GenerateHref = (params?: Params, parameterize?: boolean) => string;

const generateHrefFromSinglePattern = (
  pattern: string,
  params: Params = {},
  shouldParameterize = true
) =>
  shouldParameterize
    ? generatePath(
        pattern,
        Object.entries(params).reduce(
          (acc, [key, value]) => ({ ...acc, [key]: value && parameterize(value) }),
          {}
        )
      )
    : generatePath(pattern, params);

const canGenerateHrefFromSinglePattern = (
  pattern: string,
  params: Params = {},
  shouldParameterize = true
) => {
  try {
    generateHrefFromSinglePattern(pattern, params, shouldParameterize);
    return true;
  } catch (error) {
    return false;
  }
};

export const generateHref =
  (pattern: string | string[]): GenerateHref =>
  (params = {}, shouldParameterize = true) => {
    const patterns = Array.isArray(pattern) ? pattern : [pattern];
    const lastValidPattern = [...patterns]
      .reverse()
      .find(pattern => canGenerateHrefFromSinglePattern(pattern, params, shouldParameterize));

    return generateHrefFromSinglePattern(
      lastValidPattern || patterns[0] || '',
      params,
      shouldParameterize
    );
  };
