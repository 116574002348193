import { Text } from '@moda/om';
import React from 'react';
import classNames from 'classnames';
import { NavigatorVerticalItemFragment } from '../../../generated/types';
import { tc } from '../../../lib/trackingContext';
import { Link } from '../../Link';
import { useDesktopNavigationV2 } from '../useDesktopNavigationV2';

import './DesktopNavigationV2MainContentCategory.scss';

type Props = {
  category: NavigatorVerticalItemFragment;
  selected: boolean;
  onSelect: () => void;
};

export const DesktopNavigationV2MainContentCategory: React.FC<Props> = ({
  category,
  selected: isSelected,
  onSelect
}) => {
  const { selectedVerticalData } = useDesktopNavigationV2();

  return (
    <Link
      className={classNames('DesktopNavigationV2MainContentCategory', {
        'DesktopNavigationV2MainContentCategory--selected': isSelected,
        'DesktopNavigationV2MainContentCategory--unselected': !isSelected
      })}
      to={category.href}
      data-testid="DesktopNavigationV2MainContentCategory"
      onMouseOver={onSelect}
      onClick={event =>
        tc.track('Navigation Item Clicked', {
          addToTrackingEvent: {
            url: category.href,
            text: category.name,
            label: category.name,
            hierarchy: `${selectedVerticalData?.title}/${category.name}`,
            coordinateX: event?.pageX || 0,
            coordinateY: event?.pageY || 0
          }
        })
      }
    >
      <Text
        treatment={category.style.bold ? 'bold1' : undefined}
        color="klein-blue"
        style={category.style.color ? { color: category.style.color } : undefined}
      >
        {category.name}
      </Text>
    </Link>
  );
};
