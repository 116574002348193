import { Text } from '@moda/om';
import React, { useCallback, useMemo } from 'react';
import { MobileSiteNavOption } from '../../MobileSiteNavOption';
import { tc } from '../../../../lib/trackingContext';

interface Props {
  label: string;
  url: string;
  onClick: () => void;
  parentLabel: string;
}

export const RefreshedMobileSiteNavMenuLinkItem: React.FC<Props> = ({
  url,
  label,
  onClick,
  parentLabel
}) => {
  const href = useMemo(() => {
    if (!url) return null;

    try {
      // we can log this -- here the url looks like https://modaoperandi.com/clothing/etc and it should be strict pathname
      return new URL(url).pathname;
    } catch (error) {
      return url;
    }
  }, [url]);

  const handleClick = useCallback(
    ({ pageX: coordinateX, pageY: coordinateY }: React.MouseEvent) => {
      onClick();

      tc.track('Mobile Navigation Item Clicked', {
        addToTrackingEvent: {
          url,
          text: label,
          label,
          hierarchy: `${parentLabel}/${label}`,
          coordinateX,
          coordinateY
        }
      });
    },
    [label, onClick, parentLabel, url]
  );

  return (
    <>
      <MobileSiteNavOption href={href} onClick={handleClick}>
        <Text>{label}</Text>
      </MobileSiteNavOption>
    </>
  );
};
