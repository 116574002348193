import { gql, TypedDocumentNode } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { ONE_YEAR_SECONDS } from '@moda/portal-stanchions';
import { UseUserQuery } from '../../generated/types';
import { UserStatusLevel } from '../../constants';
import { throwQueryError } from '../../lib/apollo/throwQueryError';
import { initializeCache } from '../../lib/apollo/cache';
import { parseUser, NULL_USER, serializeUser } from '../../lib/user';
import { logoutOnAmazon } from '../../lib/amazon';
import { useLocalQuery } from '../useLocalQuery';
import { useCookies, getCookies } from '../useCookies';
import { tc } from '../../lib/trackingContext';
import { refetchFeatureFlags } from './refetchFeatureFlags';

export { UPDATE_USER_MUTATION } from './useUpdateUserMutation/useUpdateUserMutation.rest';

type UseUserQueryUser = NonNullable<UseUserQuery['user']>;

export const USER_QUERY: TypedDocumentNode<UseUserQuery, void> = gql`
  query UseUserQuery {
    user @client {
      affiliate
      accessToken
      clientTier
      countryCode
      email
      firstName
      hasPassword
      id
      isAmazonConnected
      isFacebookConnected
      isLoggedIn
      isMember
      lastName
      phone
      statusLevel
      twoFactorAuthAppEnabled
      twoFactorAuthTextMessageEnabled
      userLoyaltyStatus
    }
  }
`;

export interface SetUserProps extends Omit<UseUserQueryUser, '__typename'> {
  remember?: boolean;
  cartId?: number | null;
}

export const FULL_USER_COOKIE = 'fullUser';

export const CART_ID_COOKIE = 'cartId';

export const getUserFromCookies = () => {
  const { fullUser } = getCookies();
  return fullUser ? parseUser(fullUser) : NULL_USER;
};

export const useUser = () => {
  const { data, client, error } = useLocalQuery(USER_QUERY);
  const { setCookie, deleteCookie } = useCookies();

  const writeUserData = useCallback(
    async (user: UseUserQueryUser) => {
      const shouldResetCache = user.isLoggedIn !== data?.user?.isLoggedIn;

      if (shouldResetCache) {
        await refetchFeatureFlags();
        await client.clearStore();
        initializeCache(client, { user });
        window.dispatchEvent(new CustomEvent('resetcache'));
      } else {
        client.writeQuery({ query: USER_QUERY, data: { user } });
      }
    },
    [client, data]
  );

  const removeUserData = useCallback(() => {
    deleteCookie([FULL_USER_COOKIE, 'cartId']);
    logoutOnAmazon();

    return writeUserData(NULL_USER);
  }, [writeUserData, deleteCookie]);

  const setUserData = useCallback(
    ({
      affiliate,
      accessToken,
      cartId,
      clientTier,
      countryCode,
      email,
      firstName,
      hasPassword,
      id,
      isAmazonConnected,
      isFacebookConnected,
      isLoggedIn,
      isMember,
      lastName,
      phone,
      remember = true,
      statusLevel,
      twoFactorAuthAppEnabled,
      twoFactorAuthTextMessageEnabled,
      userLoyaltyStatus
    }: SetUserProps) => {
      const fullUser = serializeUser({
        affiliate,
        accessToken,
        clientTier,
        countryCode,
        email,
        firstName,
        hasPassword,
        id,
        isAmazonConnected,
        isFacebookConnected,
        isLoggedIn,
        isMember,
        lastName,
        phone,
        statusLevel,
        twoFactorAuthAppEnabled,
        twoFactorAuthTextMessageEnabled,
        userLoyaltyStatus
      });
      const maxAge = remember ? ONE_YEAR_SECONDS : undefined;

      setCookie(FULL_USER_COOKIE, fullUser, maxAge);
      cartId && setCookie(CART_ID_COOKIE, String(cartId), maxAge);

      return writeUserData({
        __typename: 'User',
        affiliate,
        accessToken,
        clientTier,
        countryCode,
        email,
        firstName,
        hasPassword,
        id,
        isAmazonConnected,
        isFacebookConnected,
        isLoggedIn,
        isMember,
        lastName,
        phone,
        statusLevel,
        userLoyaltyStatus,
        twoFactorAuthAppEnabled,
        twoFactorAuthTextMessageEnabled
      });
    },
    [writeUserData, setCookie]
  );

  const user = data?.user || NULL_USER;

  useEffect(() => {
    tc.setCurrent('user', user);
  }, [user]);

  /* istanbul ignore if */
  if (error) throwQueryError(error);

  return {
    removeUserData,
    setUserData,
    user,
    isEmployee: user.statusLevel === UserStatusLevel.Employee
  };
};
