import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { tryCatch } from 'ramda';
import { isClientSide } from '@moda/portal-stanchions';
import { tc } from '../../lib/trackingContext';
import { findVariantInventoryType } from '../../lib/ecomDictionary';
import { useCountry } from '../../components/CountrySelector';

import { findRouteFor } from '../../routers';
import { ProductDetailsFragment, VariantCellFragment } from '../../generated/types';

export const normalizePageName = (key: string) => {
  return key
    .split('')
    .reduce((memo, char, index, arrayOfChars) =>
      index !== 0 &&
      char !== char.toLowerCase() &&
      arrayOfChars[index - 1] === arrayOfChars[index - 1].toLowerCase()
        ? memo.concat(' ' + char)
        : memo.concat(char)
    )
    .split(' ')
    .filter((word, index, list) => !(word.toLowerCase() === 'page' && index + 1 === list.length))
    .join(' ');
};

export const usePageTracking = () => {
  const { pathname } = useLocation();
  const route = findRouteFor(pathname);
  const routeKey = route?.key ?? 'UnknownPage';
  const pagetype = route?.pagetype ?? null;
  const [title, setTitle] = useState(isClientSide() ? window.document.title : undefined);
  const [oldTitle, setOldTitle] = useState('');
  const TIMINGMS = 100;
  const intervals = useRef<number[]>([]);
  const clearIntervals = () => {
    intervals.current.forEach(interval => window.clearInterval(interval));
    intervals.current.length = 0;
  };
  let interval = 0;
  if (isClientSide()) {
    interval = window.setInterval(() => {
      if (title !== window.document.title) {
        setTitle(window.document.title);
        clearIntervals();
      }
    }, TIMINGMS);
    clearIntervals();
    intervals.current.push(interval);
  }
  const { country } = useCountry();
  const { vertical = 'women' } = useParams<{ vertical?: string }>();

  useEffect(
    function pageTrackingEffect() {
      const page = tryCatch(
        normalizePageName,
        (error: Error, pageName: string) => pageName
      )(routeKey);

      if (isClientSide() && routeKey !== undefined && title !== oldTitle) {
        const scopes = tc.getScopes();
        const variant = (scopes.variant || scopes.masterVariant) as
          | VariantCellFragment
          | ProductDetailsFragment
          | undefined;
        const ga4Title = (function () {
          switch (routeKey) {
            case 'ProductDetailPage':
              return [page, variant?.name].join('/');
            case 'ProductCategoryListingPage':
            case 'TrunkshowPage':
              return [page, scopes.plp_title].join('/');
            case 'DesignerPage':
              return [page, scopes.designer_name].join('/');
            case 'GuidePage':
              return [page, scopes.guide_page_name].join('/');
            case 'EditorialPage':
            case 'HomePage':
              return [page, scopes.display_page_name].join('/');
            default:
              return page;
          }
        })();
        tc.page(page, {
          ...tc.getDictionary().getPropsToAppendToEveryEvent(tc.getScopes()),
          pagetype,
          page,
          route_key: routeKey,
          currency: country.currencyCode,
          country_code: country.alpha2Code,
          country_code_3: country.alpha3Code,
          vertical,
          // This mess is to solve an analytics issue by putting "trunkshow" or "boutique" as "inventory_type"
          // on the PDP page call.
          // inventory_type is sent to GA using a custom dimension.
          // The variant is set in the "ProductDetails" component.
          inventory_type: findVariantInventoryType(tc.getScopes())?.toLowerCase(),
          ga4_title: ga4Title,
          category: 'Page View Events'
        });
        if (title !== undefined) setOldTitle(title);
        clearIntervals();
      }
    },
    // We explicitly don't want this to re-render when oldTitle changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [routeKey, tc, title]
  );
};
