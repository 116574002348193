import React from 'react';
import { Constrain, Divider, Stack } from '@moda/om';
import classNames from 'classnames';
import { SiteFooterMailingList } from '../SiteFooterMailingList';
import { SiteFooterBasement } from '../SiteFooterBasement';
import { SiteFooterLinks } from '../SiteFooterLinks';
import { useDesktopNavigation } from '../../../hooks/useNavigation';

import './SiteFooterLargeDesktop.scss';

export const SiteFooterLargeDesktop = () => {
  const { isDesktopSiteNavV2Enabled } = useDesktopNavigation();

  return (
    <Constrain className="SiteFooterLargeDesktop">
      <Stack space={5}>
        <div
          className={classNames('SiteFooterLargeDesktop__content', {
            'SiteFooterLargeDesktop__content--refresh': isDesktopSiteNavV2Enabled
          })}
        >
          <SiteFooterLinks />
          <SiteFooterMailingList />
        </div>
        {!isDesktopSiteNavV2Enabled && <Divider className="SiteFooterLargeDesktop__divider" />}
        <SiteFooterBasement />
      </Stack>
    </Constrain>
  );
};
