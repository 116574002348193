import { useFeatureFlag } from '@moda/portal-stanchions';
import { NavigatorPlatform } from '../../generated/types';
import { useNavigation } from './useNavigation';

export const useMobileNavigation = () => {
  const isMobileSiteNavV2Enabled = useFeatureFlag('mobile-nav-v2', [
    { default: false },
    { if: true, then: true }
  ]);

  return useNavigation(NavigatorPlatform.MOBILE, !isMobileSiteNavV2Enabled);
};
