// Import global CSS before App to ensure any library CSS is specified in the correct order
import '../global.scss';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { loadableReady } from '@loadable/component';
import { FeatureFlagResults } from '@moda/portal-stanchions';
import { loadSegment } from '@moda/portal-astrarium';
import { HelmetProvider } from 'react-helmet-async';
import { initPerfume } from 'perfume.js';
import { CONFIG } from '../config';
import { identifyUser } from '../lib/identifyUser';
import { clearFeatureFlagTrackingCache } from '../lib/trackFeatureFlagView';
import { createClientSideApolloClient } from '../lib/apollo/client';
import { isDevelopment } from '../lib/isEnv';
import { tc } from '../lib/trackingContext';
import { makeGoogleAnalyticsAdjustments } from '../lib/googleAnalytics/googleAnalyticsAdjustments';
import { initializeInsights } from '../lib/algolia/initializeInsights';
import { getUserFromCookies } from '../hooks/useUser';
import { getCookies } from '../hooks/useCookies';
import { BreakpointProvider } from '../components/BreakpointProvider';
import { App } from '../App';
import { initializeEvidon, determinePolicy } from '../lib/evidon/evidon';
import { ga4DeviceMode } from '../lib/googleAnalytics/ga4/ga4DeviceMode';

// TODO: move this polyfill to it's own chunk and load it only when required
import 'intersection-observer';
import 'isomorphic-fetch';
import 'focus-visible';
import { DiscoveryFeatureFlagProvider } from '../components/DiscoveryFeatureFlagProvider';

declare global {
  interface Window {
    analytics: AnalyticsBrowser;
    __FEATURE_FLAGS__?: FeatureFlagResults;
    __SERVER_ERROR__?: boolean;
    __SSR_WIDTH__?: number;
  }
}

const el = document.getElementById('root');

const history = createBrowserHistory();
const client = createClientSideApolloClient(history);

history.listen(() => clearFeatureFlagTrackingCache());

initializeEvidon();
initializeInsights();

const { modaAnonymousId } = getCookies();

const SEGMENT_CDN_HOST = 'https://cdn.segment.com';
const SEGMENT_API_HOST = 'api.segment.io/v1';

function loadSegmentWithTags() {
  loadSegment(CONFIG.SEGMENT_WRITE_KEY, {
    cdnHost: SEGMENT_CDN_HOST,
    integrations: {
      All: true,
      'Segment.io': { apiHost: SEGMENT_API_HOST }
    }
  });
}

function loadSegmentWithoutTags() {
  loadSegment(CONFIG.SEGMENT_WRITE_KEY, {
    cdnHost: SEGMENT_CDN_HOST,
    anonymousId: modaAnonymousId,
    integrations: {
      All: false,
      'Segment.io': { apiHost: SEGMENT_API_HOST },
      'Google Analytics': true
    }
  });
}

determinePolicy()
  .then(determination => {
    switch (true) {
      case determination.policy.length === 0:
      case determination.policy.includes("don't load tags"):
        loadSegmentWithoutTags();
        break;
      case determination.policy.includes('load tags'):
        loadSegmentWithTags();
        break;
      default:
        loadSegmentWithoutTags();
    }
  })
  .catch(() => {
    loadSegmentWithoutTags();
  })
  .finally(async () => {
    const measurementID = CONFIG['GA4_MEASUREMENT_ID'];
    if (measurementID != null && measurementID !== '') {
      // Load GA4 Device Mode plugin
      await window.analytics.register(ga4DeviceMode);
    }

    identifyUser(getUserFromCookies());

    makeGoogleAnalyticsAdjustments();

    const events = {
      cls: 'Cumulative Layout Shift',
      lcp: 'Largest Contentful Paint',
      fidVitals: 'First Input Delay'
    } as const;
    function isValidEvent(event: string): event is keyof typeof events {
      return Object.keys(events).includes(event);
    }

    try {
      initPerfume({
        analyticsTracker: options => {
          const { data, metricName, navigatorInformation, rating } = options;
          const eventData = {
            category: 'Performance',
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            value: metricName === 'cls' ? Math.round(Number(data) * 1000) : data,
            vitalsScore: rating,
            label: navigatorInformation.isLowEndDevice ? 'Low End Device' : 'High End Device',
            nonInteraction: 1
          };
          if (isValidEvent(metricName)) {
            tc.track(events[metricName], { addToTrackingEvent: eventData });
          }
        }
      });
    } catch (error) {
      // Intentionally doing nothing here.
    }

    const ClientSideApp = (
      <DiscoveryFeatureFlagProvider>
        <Router history={history}>
          <ApolloProvider client={client}>
            <HelmetProvider>
              <BreakpointProvider ssrWidth={window.__SSR_WIDTH__}>
                <App error={window.__SERVER_ERROR__} />
              </BreakpointProvider>
            </HelmetProvider>
          </ApolloProvider>
        </Router>
      </DiscoveryFeatureFlagProvider>
    );

    const init = () => {
      if (!el) throw new Error('#root element not found');

      isDevelopment()
        ? ReactDOM.createRoot(el).render(ClientSideApp)
        : loadableReady(() => ReactDOM.hydrateRoot(el, ClientSideApp));
    };

    init();
  });
