import { Clickable, Divider, Stack, Text } from '@moda/om';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import ExpandIcon from '@moda/icons/expand-12';
import HideIcon from '@moda/icons/hide-12';
import { colors } from '@moda/tokens';
import { RefreshedMobileSiteNavMenuQuery } from '../../../../generated/types';
import { tc } from '../../../../lib/trackingContext';
import { logger } from '../../../../lib/logger';

import './RefreshedMobileSiteNavExpandable.scss';

type StyleProps = NonNullable<
  RefreshedMobileSiteNavMenuQuery['displayPage']
>['data']['tabs'][number]['mainCategories'][number]['styleProps'];

interface Props {
  title: string;
  children: React.ReactNode;
  url?: string;
  styleProps: StyleProps | null;
  expandedSection: string | undefined;
  setExpandedSection: (label: string | undefined) => void;
  parentLabel: string;
}

export const RefreshedMobileSiteNavExpandable: React.FC<Props> = ({
  title,
  children,
  url,
  styleProps,
  expandedSection,
  setExpandedSection,
  parentLabel
}) => {
  const hasChildren = useMemo(() => {
    const childrenCount = React.Children.toArray(children).length;
    if (childrenCount > 0) return true;

    return false;
  }, [children]);

  const href = useMemo(() => {
    if (!url) return undefined;

    if (hasChildren) return undefined;

    try {
      // we can log this -- here the url looks like https://modaoperandi.com/clothing/etc and it should be strict pathname
      return new URL(url).pathname;
    } catch (error) {
      return url;
    }
  }, [hasChildren, url]);

  const Icon = useMemo(() => {
    if (expandedSection === title) {
      return HideIcon;
    }
    return ExpandIcon;
  }, [expandedSection, title]);

  const handleTrackMobileNavItemClicked = useCallback(
    (text: string | null, event: React.MouseEvent) => {
      if (typeof parentLabel === 'undefined') {
        logger.info('MISSING NAV STRUCTURE DATA', text || 'missing text');
      }

      tc.track('Mobile Navigation Item Clicked', {
        addToTrackingEvent: {
          text,
          label: text,
          hierarchy: `${parentLabel}/${text}`,
          coordinateX: event?.pageX || 0,
          coordinateY: event?.pageY || 0
        }
      });
    },
    [parentLabel]
  );

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      setExpandedSection(expandedSection === title ? undefined : title);
      handleTrackMobileNavItemClicked(title, event);
    },
    [setExpandedSection, expandedSection, title, handleTrackMobileNavItemClicked]
  );

  return (
    <Stack space={3} className="RefreshedMobileSiteNavExpandable">
      <Clickable
        className="RefreshedMobileSiteNavExpandable__title"
        href={href}
        onClick={(event: React.MouseEvent) => {
          handleClick(event);
        }}
      >
        <Stack alignItems="center" direction="horizontal" justifyContent="space-between" space={2}>
          <Text style={{ color: title === 'SALE' ? colors.all['code-red'] : colors.all.ink }}>
            {title}
          </Text>

          {hasChildren && <Icon />}
        </Stack>
      </Clickable>

      <div
        className={classNames('RefreshedMobileSiteNavExpandable__content', {
          'RefreshedMobileSiteNavExpandable__content--expanded':
            expandedSection === title && title !== 'Designers',
          'RefreshedMobileSiteNavExpandable__content-designers--expanded':
            expandedSection === title && title === 'Designers'
        })}
      >
        {children}
      </div>

      {styleProps?.hasDividerAfter && <Divider />}
    </Stack>
  );
};
