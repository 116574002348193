import React from 'react';
import { Divider, Stack, Text } from '@moda/om';
import { NavigatorUnitListItem, NavigatorUnitListItemType } from '../../../generated/types';
import { MobileSiteNavOption } from '../../SiteNav/MobileSiteNavOption';

import './MobileNavV2SubcategoriesSection.scss';

interface Props {
  data?: NavigatorUnitListItem[];
  className?: string;
  onClose: () => void;
}

export const MobileNavV2SubcategoriesSection: React.FC<Props> = ({ data, className, onClose }) => {
  if (!data) return null;

  return (
    <Stack className="MobileNavV2SubcategoriesSection" space={1}>
      {data.map(({ title, href, type }, index) => (
        <React.Fragment key={`${title} - ${index}`}>
          {type === NavigatorUnitListItemType.Section && (
            <>
              <Divider />

              <Text className="MobileNavV2SubcategoriesSection__sub-section" treatment="bold1">
                {title}
              </Text>

              <Divider />
            </>
          )}

          {type === NavigatorUnitListItemType.Default && (
            <MobileSiteNavOption
              className={className}
              key={`${title} - ${index}`}
              href={href}
              onClick={onClose}
            >
              {title}
            </MobileSiteNavOption>
          )}
        </React.Fragment>
      ))}
    </Stack>
  );
};
