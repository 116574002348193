import { gql } from '@apollo/client';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import LogoHorizontalIcon from '@moda/icons/logo-horizontal-189-x-12';
import { Constrain } from '@moda/om';
import { useLocation } from 'react-router-dom';
import { useFeatureFlag } from '@moda/portal-stanchions';
import classNames from 'classnames';
import { DesktopSiteNavFragment } from '../../../generated/types';
import { Link } from '../../Link';
import { useEnableClubModaTreatment } from '../../../hooks/useEnableClubModaTreatment';
import { DesktopNavigationV2 } from '../../DesktopNavigationV2';
import { DesktopNavigationV2MainContent } from '../../DesktopNavigationV2/DesktopNavigationV2MainContent';
import { DesktopSiteNavActions } from './DesktopSiteNavActions';
import { DesktopSiteNavItem, DESKTOP_NAV_ITEM_FRAGMENT } from './DesktopSiteNavItem';
import { DesktopSiteNavItemLoading } from './DesktopSiteNavItemLoading';

import './DesktopSiteNav.scss';

export const DESKTOP_SITE_NAV_FRAGMENT = gql`
  fragment DesktopSiteNavFragment on DisplayPage {
    navItems {
      ...DesktopSiteNavItemFragment
    }
  }
  ${DESKTOP_NAV_ITEM_FRAGMENT}
`;

export interface Props {
  displayPage?: DesktopSiteNavFragment | null;
}

const ACTIVATE_DELAY_MS = 250;

export const DesktopSiteNav: React.FC<Props> = ({ displayPage, ...rest }) => {
  const location = useLocation();

  const isDesktopSiteNavV2Enabled = useFeatureFlag('desktop-nav-v2', [
    { default: false },
    { if: true, then: true }
  ]);

  const navItems = displayPage?.navItems?.filter(item => !item.mobileOnly);

  const [active, setActive] = useState(false);

  const interval = useRef<NodeJS.Timeout>();

  const handleMouseEnter = useCallback(
    () => (interval.current = global.setTimeout(() => setActive(true), ACTIVATE_DELAY_MS)),
    []
  );

  const handleMouseLeave = useCallback(() => {
    interval.current && clearInterval(interval.current);
    setActive(false);
  }, []);

  const enableClubModaTreatment = useEnableClubModaTreatment();

  useEffect(() => {
    return () => interval.current && clearInterval(interval.current);
  }, []);

  // When the route changes, always close the nav
  useEffect(() => setActive(false), [location]);

  return (
    <nav className="DesktopSiteNav" {...rest}>
      <Constrain className="DesktopSiteNav__primary">
        {/* temporary empty div until new design for nav */}

        {!isDesktopSiteNavV2Enabled && <div />}

        {isDesktopSiteNavV2Enabled && <DesktopNavigationV2 />}

        <div className="DesktopSiteNav__logo" data-testid="logo">
          <Link aria-label="Moda Operandi Home Page" to="/">
            <LogoHorizontalIcon
              width="100%"
              height="100%"
              className={classNames('DesktopSiteNav__logo-icon', {
                'DesktopSiteNav__logo-icon--club': enableClubModaTreatment
              })}
            />
          </Link>
        </div>
        <DesktopSiteNavActions />
      </Constrain>

      {isDesktopSiteNavV2Enabled && (
        <Constrain>
          <DesktopNavigationV2MainContent />
        </Constrain>
      )}

      <div
        className={classNames('DesktopSiteNav__nav-items', {
          'DesktopSiteNav__nav-items--club': enableClubModaTreatment
        })}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-testid="desktopNavItems"
      >
        {!isDesktopSiteNavV2Enabled && (
          <>
            {!navItems && <DesktopSiteNavItemLoading />}

            {navItems &&
              navItems.map((navItem, index) => (
                <DesktopSiteNavItem
                  className="DesktopSiteNav__nav-item"
                  key={navItem.id || index}
                  navItem={navItem}
                  active={active}
                />
              ))}
          </>
        )}
      </div>
    </nav>
  );
};
