import React from 'react';
import { Stack } from '@moda/om';
import { useDesktopNavigation } from '../../hooks/useNavigation';
import { DesktopSiteNavItemLoading } from '../SiteNav/DesktopSiteNav/DesktopSiteNavItemLoading';
import { throwQueryError } from '../../lib/apollo/throwQueryError';
import { DesktopNavigationV2Vertical } from './DesktopNavigationV2Vertical';

export const DesktopNavigationV2 = () => {
  const {
    navigation: { data, loading, error }
  } = useDesktopNavigation();

  if (error) throwQueryError(error);

  if (loading) return <DesktopSiteNavItemLoading />;

  if (!data) return null;

  return (
    <Stack direction="horizontal" space={5} className="DesktopNavigationV2">
      {data.verticals.map(vertical => (
        <DesktopNavigationV2Vertical key={vertical.id} vertical={vertical} />
      ))}
    </Stack>
  );
};
