import React from 'react';
import { Constrain, Divider, Stack } from '@moda/om';
import classNames from 'classnames';
import { SiteFooterMailingList } from '../SiteFooterMailingList';
import { SiteFooterBasement } from '../SiteFooterBasement';
import { SiteFooterLinks } from '../SiteFooterLinks';
import { useDesktopNavigation } from '../../../hooks/useNavigation';

import './SiteFooterDesktop.scss';

export const SiteFooterDesktop = () => {
  const { isDesktopSiteNavV2Enabled } = useDesktopNavigation();

  return (
    <Constrain className="SiteFooterDesktop">
      <Stack space={5}>
        <SiteFooterMailingList className="SiteFooterDesktop__mailing-list" />
        <SiteFooterLinks
          className={classNames('SiteFooterDesktop__links', {
            'SiteFooterDesktop__links--refresh': isDesktopSiteNavV2Enabled
          })}
        />
        {!isDesktopSiteNavV2Enabled && <Divider className="SiteFooterDesktop__divider" />}
        <SiteFooterBasement />
      </Stack>
    </Constrain>
  );
};
