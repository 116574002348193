import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';
import sha from 'sha.js';
import { TextInput, Clickable, Text, Stack, ControlLink } from '@moda/om';
import ChevronRightIcon from '@moda/icons/chevron-right-20';
import SocialPhoneIcon from '@moda/icons/social-phone-16';
import MailIcon from '@moda/icons/mail-16';
import { useFeatureFlag } from '@moda/portal-stanchions';
import { hrefFor } from '../../../routers';
import { Link } from '../../Link';
import { useEmailSubscribe } from '../../../hooks/useEmailSubscribe';

import './SiteFooterMailingList.scss';

export const SiteFooterMailingList: FC<{ className?: string }> = ({ className }) => {
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  const {
    subscribe,
    isExistingSubscription,
    error,
    mailValidationError,
    isPhoneNumberValid,
    phoneNumberValidationError,
    phoneNumberEnabled
  } = useEmailSubscribe();

  const [emailStep, setEmailStep] = useState(true);
  const [smsStep, setSmsStep] = useState<boolean>(false);
  const [confirmationStep, setConfirmationStep] = useState<boolean>(false);

  const emailSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      subscribe({ email, label: 'footer' });
      setEmailStep(false);
      phoneNumberEnabled ? setSmsStep(true) : setConfirmationStep(true);
    },
    [email, phoneNumberEnabled, setEmailStep, subscribe, setSmsStep, setConfirmationStep]
  );

  const smsSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      if (!isPhoneNumberValid(phone)) return;
      subscribe({ email, phone, label: 'footer' });
      setSmsStep(false);
      setConfirmationStep(true);
    },
    [email, phone, subscribe, setSmsStep, setConfirmationStep, isPhoneNumberValid]
  );

  // The feature flag is a number, but we'll want a string to display.
  const welcomeOffer = useFeatureFlag('configuration-set-welcome-offer', [
    { default: '15' },
    { getFromTreatment: treatment => String(treatment) ?? '15' }
  ]);

  return (
    <Stack className={classNames('SiteFooterMailingList__wrapper', className)} space={4}>
      {(emailStep || smsStep) && (
        <Text treatment="eyebrow" className="SiteFooterMailingList__title">
          GET ON THE LIST
        </Text>
      )}
      {emailStep && (
        <form className="SiteFooterMailingList" onSubmit={emailSubmit}>
          <Text treatment="body1" color="ink">
            Receive {welcomeOffer}% off* your first purchase and stay up to date on our latest
            arrivals, exclusive offers, and more with alerts sent directly to your inbox{' '}
            {phoneNumberEnabled && <> or phone via text.</>}
          </Text>

          <div className="SiteFooterMailingList__input">
            <TextInput
              className="SiteFooterMailingList__input-button"
              error={mailValidationError}
              onChange={setEmail}
              placeholder="Email Address"
              required
              type="email"
              value={email}
            />

            <Clickable
              aria-label="Submit email for our newsletter"
              className="SiteFooterMailingList__button"
              type="submit"
            >
              <ChevronRightIcon />
            </Clickable>
          </div>

          <Stack space={2} className="SiteFooterMailingList__terms">
            <Text treatment="body2" color="cement">
              *Terms and Conditions Apply
            </Text>

            <Text treatment="body2" color="cement">
              View{' '}
              <ControlLink
                to={hrefFor.TermsAndConditionsPage()}
                className="SiteFooterMailingList__footer-link"
                underlined
                rel="noopener noreferrer"
                target="_blank"
              >
                <Text treatment="body2" color="cement">
                  Terms
                </Text>
              </ControlLink>{' '}
              &amp;{' '}
              <ControlLink
                to={hrefFor.PrivacyPolicyPage()}
                className="SiteFooterMailingList__footer-link"
                underlined
                rel="noopener noreferrer"
                target="_blank"
              >
                <Text treatment="body2" color="cement">
                  Privacy Policy
                </Text>
              </ControlLink>
            </Text>
          </Stack>
        </form>
      )}
      {smsStep && (
        <form className={classNames('SiteFooterMailingList')} onSubmit={smsSubmit}>
          <Text treatment="body2" color="cement">
            †By submitting this form, you agree to receive recurring automated promotional and
            personalized marketing text messages (e.g. cart reminders) from Moda Operandi at the
            cell number used when signing up. Consent is not a condition of any purchase. Reply HELP
            for help and STOP to cancel. Msg frequency varies. Msg & data rates may apply.
          </Text>

          <div className="SiteFooterMailingList__input">
            <TextInput
              className="SiteFooterMailingList__input-button"
              error={phoneNumberValidationError}
              onChange={setPhone}
              placeholder="Mobile Phone"
              required
              type="tel"
              value={phone}
            />
            <Clickable
              aria-label="Submit email for our newsletter"
              className="SiteFooterMailingList__button"
              type="submit"
            >
              Sign up for texts
            </Clickable>
          </div>

          {error && (
            <div
              className={classNames('SiteFooterMailingList__status', {
                'SiteFooterMailingList__status--error': error
              })}
            >
              Unable to subscribe you at this time
            </div>
          )}

          <Stack space={2} className="SiteFooterMailingList__footer">
            <Text treatment="body2" color="cement">
              *Terms and Conditions Apply
            </Text>

            <Text treatment="body2" color="cement">
              View{' '}
              <ControlLink
                to={hrefFor.TermsAndConditionsPage()}
                className="SiteFooterMailingList__footer-link"
                underlined
                rel="noopener noreferrer"
                target="_blank"
              >
                <Text treatment="body2" color="cement">
                  Terms
                </Text>
              </ControlLink>{' '}
              &amp;{' '}
              <ControlLink
                to={hrefFor.PrivacyPolicyPage()}
                className="SiteFooterMailingList__footer-link"
                underlined
                rel="noopener noreferrer"
                target="_blank"
              >
                <Text treatment="body2" color="cement">
                  Privacy Policy
                </Text>
              </ControlLink>
            </Text>
          </Stack>
        </form>
      )}

      {confirmationStep && (
        <div className={classNames('SiteFooterMailingList')}>
          <Text treatment="eyebrow">
            Check your {phoneNumberEnabled ? <span>texts</span> : <span>inbox</span>}
          </Text>
          <div className="SiteFooterMailingList__confirmation">
            {phoneNumberEnabled ? (
              <SocialPhoneIcon
                color="goldenrod"
                width="36px"
                height="36px"
                className="SiteFooterMailingList__icon"
              />
            ) : (
              <MailIcon
                color="goldenrod"
                width="36px"
                height="36px"
                className="SiteFooterMailingList__icon"
              />
            )}
            <Text
              className="SiteFooterMailingList__confirmationtext"
              treatment="body1"
              color="cement"
            >
              {phoneNumberEnabled ? (
                <span>
                  Reply “Y” to confirm your subscription. We look forward to sharing early access on
                  products, exclusive offers, and more.
                </span>
              ) : (
                <span aria-label="Confirmation message">
                  A confirmation email will be sent to you shortly with your {welcomeOffer}% off
                  code.
                </span>
              )}
            </Text>
          </div>
        </div>
      )}

      {error && (
        <div
          className={classNames('SiteFooterMailingList__status', {
            'SiteFooterMailingList__status--error': error
          })}
        >
          Unable to subscribe you at this time
        </div>
      )}

      {phoneNumberValidationError && (
        <div
          className={classNames('SiteFooterMailingList__status', {
            'SiteFooterMailingList__status--error': error
          })}
        >
          {phoneNumberValidationError}
        </div>
      )}

      {isExistingSubscription && (
        <>
          <div
            className={classNames(
              'SiteFooterMailingList__status',
              'SiteFooterMailingList__status--success'
            )}
          >
            You're already on our newsletter list,{' '}
            <Link
              className="SiteFooterMailingList__manage-subscriptions-link"
              to={`${hrefFor.UnsubscribePage()}?email_hash=${sha('sha256')
                .update(email)
                .digest('hex')}`}
            >
              click here
            </Link>{' '}
            to manage your subscriptions.
          </div>
          <div className="SiteFooterMailingList__opt-out">
            To opt out,{' '}
            <Link className="SiteFooterMailingList__opt-out-link" to={hrefFor.UnsubscribePage()}>
              unsubscribe here
            </Link>{' '}
            or in our emails.
          </div>
        </>
      )}
    </Stack>
  );
};
