import { usePrevious } from '@moda/portal-stanchions';
import { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { useCart } from '../../hooks/useCart';
import { useCookies } from '../../hooks/useCookies';

const JUST_ADDED_DURATION_MS = 4000;

export const useJustAddedItems = () => {
  const [justAddedItemIds, setJustAddedItemIds] = useState<string[]>([]);
  const [isNewCart, setIsNewCart] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const {
    cookies: { cartId }
  } = useCookies();
  const { cart, loaded } = useCart({ ssr: false });
  const cartItems = useMemo(() => cart.attributes.cartItems.map(item => item.data), [cart]);
  const previousCartItems = usePrevious(cartItems);
  const previousLoaded = usePrevious(loaded);

  const newCartItems = useMemo(
    () =>
      cartItems.filter(
        item =>
          !previousCartItems?.find(
            previousItem =>
              previousItem.id === item.id &&
              previousItem.attributes.quantity === item.attributes.quantity
          )
      ),
    [cartItems, previousCartItems]
  );

  useEffect(() => {
    if (!cartId) {
      setIsNewCart(true);
    }
  }, [cartId, setIsNewCart]);

  useEffect(() => {
    if (newCartItems.length > 0 && (previousLoaded || isNewCart)) {
      setJustAddedItemIds(newCartItems.map(item => item.id));
      setIsNewCart(false);
    }
  }, [newCartItems, setJustAddedItemIds, previousLoaded, isNewCart]);

  useEffect(() => {
    if (justAddedItemIds.length > 0) {
      timeoutRef.current = global.setTimeout(() => setJustAddedItemIds([]), JUST_ADDED_DURATION_MS);
      return () => {
        timeoutRef.current && clearTimeout(timeoutRef.current);
      };
    }
  }, [justAddedItemIds, setJustAddedItemIds, timeoutRef]);

  const justAddedItems = useMemo(
    () => cartItems.filter(item => justAddedItemIds.includes(item.id)),
    [cartItems, justAddedItemIds]
  );

  const dismiss = useCallback(() => setJustAddedItemIds([]), [setJustAddedItemIds]);
  const persist = useCallback(
    () => timeoutRef.current && clearTimeout(timeoutRef.current),
    [timeoutRef]
  );

  return {
    justAddedItems,
    dismiss,
    persist
  };
};
