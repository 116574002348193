import React from 'react';
import loadable from '@loadable/component';
import { Route, RouteProps, matchPath } from 'react-router-dom';
import { values } from 'ramda';
import {
  PageType,
  TRUNKSHOW_SEASON_REGEXP,
  AccountOrderDetailPageState,
  EMAIL_VERIFICATION_STATUSES
} from '../constants';
import { Loading } from '../components/Loading';
import { renderWithStatusCode } from './renderWithStatusCode';
import { generateHref, GenerateHref } from './generateHref';
import {
  ROUTE_VERTICALS,
  VERTICALS_WITHOUT_HOME_PAGE,
  SECTIONS,
  ROUTE_CATEGORIES
} from './constants';
import { ACCOUNT_ROUTES } from './AccountRoutes';

const FavoritesPage = loadable(() => import('../pages/FavoritesPage'), {
  fallback: <Loading />
});
const ForgotPasswordPage = loadable(() => import('../pages/ForgotPasswordPage'), {
  fallback: <Loading />
});
const ResetPasswordPage = loadable(() => import('../pages/ResetPasswordPage'), {
  fallback: <Loading />
});
const HomePage = loadable(() => import('../pages/HomePage'), { fallback: <Loading /> });
const BeautyLandingPage = loadable(() => import('../pages/BeautyLandingPage'), {
  fallback: <Loading />
});
const HomeLandingPage = loadable(() => import('../pages/HomeLandingPage'), {
  fallback: <Loading />
});
const GuidePage = loadable(() => import('../pages/GuidePage'), { fallback: <Loading /> });
const EmployeePage = loadable(() => import('../pages/EmployeePage'), { fallback: <Loading /> });
const DesignerPage = loadable(() => import('../pages/DesignerPage'), { fallback: <Loading /> });
const EditorialPage = loadable(() => import('../pages/EditorialPage'), { fallback: <Loading /> });
const LogoutPage = loadable(() => import('../pages/LogoutPage'), { fallback: <Loading /> });
const UnlockAccountPage = loadable(() => import('../pages/UnlockAccountPage'), {
  fallback: <Loading />
});
const ProductSearchListingPage = loadable(() => import('../pages/ProductSearchListingPage'), {
  fallback: <Loading />
});
const ProductCategoryListingPage = loadable(() => import('../pages/ProductCategoryListingPage'), {
  fallback: <Loading />
});
const ProductDetailPage = loadable(() => import('../pages/ProductDetailPage'), {
  fallback: <Loading />
});
const RegisterPage = loadable(() => import('../pages/RegisterPage'), { fallback: <Loading /> });
const TrunkshowPage = loadable(() => import('../pages/TrunkshowPage'), {
  fallback: <Loading />
});
const TrunkshowIndexPage = loadable(() => import('../pages/TrunkshowIndexPage'), {
  fallback: <Loading />
});
const DesignersIndexPage = loadable(() => import('../pages/DesignersIndexPage'), {
  fallback: <Loading />
});
const LookviewContainer = loadable(() => import('../pages/LookviewPage'), {
  fallback: <Loading />
});
const ShoppingBagPage = loadable(() => import('../pages/ShoppingBagPage'), {
  fallback: <Loading />
});
const CheckoutPage = loadable(() => import('../pages/CheckoutPage'), {
  fallback: <Loading />
});
const OrderConfirmationPage = loadable(() => import('../pages/OrderConfirmationPage'), {
  fallback: <Loading />
});
const UnsubscribePage = loadable(() => import('../pages/UnsubscribePage'), {
  fallback: <Loading />
});
const TermsAndConditionsPage = loadable(() => import('../pages/TermsAndConditionsPage'), {
  fallback: <Loading />
});
const LegalPage = loadable(() => import('../pages/LegalPage'), {
  fallback: <Loading />
});
const LegalBrandGuideLinesPage = loadable(() => import('../pages/LegalBrandGuideLinesPage'), {
  fallback: <Loading />
});
const LegalTradeMarksPage = loadable(() => import('../pages/LegalTradeMarksPage'), {
  fallback: <Loading />
});
const TrunkshowReferAFriendTermsPage = loadable(
  () => import('../pages/TrunkshowReferAFriendTermsPage'),
  {
    fallback: <Loading />
  }
);
const PrivacyPolicyPage = loadable(() => import('../pages/PrivacyPolicyPage'), {
  fallback: <Loading />
});
const RewardsPage = loadable(() => import('../pages/RewardsPage'), {
  fallback: <Loading />
});
const AboutPage = loadable(() => import('../pages/AboutPage'), {
  fallback: <Loading />
});
const ModaPrivatePage = loadable(() => import('../pages/ModaPrivatePage'), {
  fallback: <Loading />
});
const ModaNetworkPage = loadable(() => import('../pages/ModaNetworkPage'), {
  fallback: <Loading />
});
const OrderDetailPage = loadable(() => import('../pages/OrderDetailPage'), {
  fallback: <Loading />
});
const ReturnSummaryPage = loadable(() => import('../pages/ReturnSummaryPage'), {
  fallback: <Loading />
});
const RequestReturnPage = loadable(() => import('../pages/RequestReturnPage'), {
  fallback: <Loading />
});
const ReturnConfirmationPage = loadable(() => import('../pages/ReturnConfirmationPage'), {
  fallback: <Loading />
});
const CancelRequestPage = loadable(() => import('../pages/CancelRequestPage'), {
  fallback: <Loading />
});
const CancelConfirmationPage = loadable(() => import('../pages/CancelConfirmationPage'), {
  fallback: <Loading />
});
const PrivacyEventPhotography = loadable(() => import('../pages/PrivacyEventPhotography'), {
  fallback: <Loading />
});
const OptionsPage = loadable(() => import('../pages/OptionsPage'), {
  fallback: <Loading />
});
const PrivacyApplicantCandidatePage = loadable(
  () => import('../pages/PrivacyApplicantCandidatePage'),
  {
    fallback: <Loading />
  }
);

type PageName =
  | 'HomePage'
  | 'EmailVerificationStatusPage'
  | 'BeautyLandingPage'
  | 'HomeLandingPage'
  | 'RegisterPage'
  | 'FavoritesPage'
  | 'ForgotPasswordPage'
  | 'ResetPasswordPage'
  | 'LogoutPage'
  | 'UnlockAccountPage'
  | 'DesignersIndexPage'
  | 'EditorialPage'
  | 'ProductDetailPage'
  | 'ProductCategoryListingPage'
  | 'ProductSearchListingPage'
  | 'TrunkshowPage'
  | 'LookviewPage'
  | 'TrunkshowIndexPage'
  | 'GuidePage'
  | 'EmployeePage'
  | 'DesignerPage'
  | 'ShoppingBagPage'
  | 'CheckoutPage'
  | 'OrderConfirmationPage'
  | 'UnsubscribePage'
  | 'TermsAndConditionsPage'
  | 'TrunkshowReferAFriendTermsPage'
  | 'PrivacyPolicyPage'
  | 'RewardsPage'
  | 'AboutPage'
  | 'ModaPrivate'
  | 'OrderDetailPage'
  | 'ReturnSummaryPage'
  | 'ReturnConfirmationPage'
  | 'AccountSettings'
  | 'AccountDesignerPreferences'
  | 'AccountShippingAddresses'
  | 'AccountPaymentMethods'
  | 'AccountOrderHistory'
  | 'AccountOpenItems'
  | 'AccountShipments'
  | 'AccountReturns'
  | 'AccountSiteCredits'
  | 'AccountRewards'
  | 'RequestReturnPage'
  | 'CancelRequestPage'
  | 'CancelConfirmationPage'
  | 'PrivacyEventPhotography'
  | 'PrivacyApplicantCandidatePage'
  | 'OptionsPage'
  | 'LegalPage'
  | 'LegalBrandGuideLinesPage'
  | 'LegalTradeMarksPage'
  | 'ModaNetwork';

export type PageRoute = RouteProps & {
  key: PageName;
  path: string | string[];
  generateHref?: GenerateHref;
  component: ReturnType<typeof renderWithStatusCode>;
  pagetype: PageType;
};

const TRUNKSHOW_PATHS = {
  TRUNKSHOW_SLUG: `/collection/:trunkshowSlug`,
  DESIGNER_SLUG_AND_SEASON: `/designer/:designerSlug/collection/:season`
};

const TRUNKSHOW_INDEX_PATHS = {
  DESIGNERS: '/collections/:mode(designers)/:category?',
  PIECES: `/collections/:vertical(${ROUTE_VERTICALS})?/:category(${ROUTE_CATEGORIES})?/:subcategory?/:attributes?`
};

export const ROUTES: PageRoute[] = [
  {
    key: 'HomePage',
    path: '/',
    component: renderWithStatusCode(HomePage),
    exact: true,
    pagetype: PageType.Home
  },
  {
    key: 'EmailVerificationStatusPage',
    path: `/email-verification-status/:status(${EMAIL_VERIFICATION_STATUSES.join('|')})`,
    component: renderWithStatusCode(HomePage),
    exact: true,
    pagetype: PageType.Home
  },
  {
    key: 'BeautyLandingPage',
    path: '/beauty',
    component: renderWithStatusCode(BeautyLandingPage),
    exact: true,
    pagetype: PageType.Home
  },
  {
    key: 'HomeLandingPage',
    path: '/home',
    component: renderWithStatusCode(HomeLandingPage),
    exact: true,
    pagetype: PageType.Home
  },
  {
    key: 'RegisterPage',
    path: '/register',
    component: renderWithStatusCode(RegisterPage),
    exact: true,
    pagetype: PageType.Account
  },
  {
    key: 'FavoritesPage',
    path: '/favorites',
    component: renderWithStatusCode(FavoritesPage),
    exact: true,
    pagetype: PageType.Favorites
  },
  {
    key: 'ForgotPasswordPage',
    path: '/forgot-password',
    component: renderWithStatusCode(ForgotPasswordPage),
    exact: true,
    pagetype: PageType.Account
  },
  {
    key: 'ResetPasswordPage',
    path: '/reset-password',
    component: renderWithStatusCode(ResetPasswordPage),
    exact: true,
    pagetype: PageType.Account
  },
  {
    key: 'LogoutPage',
    path: '/logout',
    component: renderWithStatusCode(LogoutPage),
    exact: true,
    pagetype: PageType.Account
  },
  {
    key: 'UnlockAccountPage',
    path: '/users/unlock',
    component: renderWithStatusCode(UnlockAccountPage),
    exact: true,
    pagetype: PageType.Account
  },
  {
    key: 'DesignersIndexPage',
    path: [
      '/designers',
      `/:vertical(${ROUTE_VERTICALS})/designers/:category(${ROUTE_CATEGORIES})?`
    ],
    component: renderWithStatusCode(DesignersIndexPage),
    exact: true,
    pagetype: PageType.Designers
  },
  {
    key: 'EditorialPage',
    path: `/editorial/:slug`,
    component: renderWithStatusCode(EditorialPage),
    exact: true,
    pagetype: PageType.Editorial
  },
  {
    key: 'ProductDetailPage',
    path: `/:vertical(${ROUTE_VERTICALS})/p/:designerSlug/:productSlug/:variantId`,
    component: renderWithStatusCode(ProductDetailPage),
    exact: true,
    pagetype: PageType.ProductDetailPage
  },
  {
    key: 'ProductCategoryListingPage',
    path: [
      `/:section(${SECTIONS})`,
      `/:vertical(${VERTICALS_WITHOUT_HOME_PAGE})`,
      '/:vertical(beauty)/products/all',
      '/:vertical(home)/products/all',
      `/:vertical(${ROUTE_VERTICALS})/products/:category(${ROUTE_CATEGORIES})/:subcategory?/:attributes?`,
      `/:vertical(${ROUTE_VERTICALS})/products/:section(${SECTIONS})/:category(${ROUTE_CATEGORIES})?/:subcategory?/:attributes?`
    ],
    component: renderWithStatusCode(ProductCategoryListingPage),
    exact: true,
    pagetype: PageType.ProductListingPage
  },
  {
    key: 'ProductSearchListingPage',
    path: `/search`,
    component: renderWithStatusCode(ProductSearchListingPage),
    exact: true,
    pagetype: PageType.SearchResults
  },
  {
    key: 'TrunkshowPage',
    path: [TRUNKSHOW_PATHS.TRUNKSHOW_SLUG, TRUNKSHOW_PATHS.DESIGNER_SLUG_AND_SEASON],
    generateHref: (params, parameterize) => {
      const trunkshowSlugParts = params?.trunkshowSlug?.split('-');
      const designerSlug = params?.designerSlug || trunkshowSlugParts?.slice(0, -1).join('-');
      const season = params?.season || trunkshowSlugParts?.slice(-1)[0];
      return designerSlug && season && TRUNKSHOW_SEASON_REGEXP.test(season)
        ? generateHref(TRUNKSHOW_PATHS.DESIGNER_SLUG_AND_SEASON)(
            { ...params, designerSlug, season },
            parameterize
          )
        : generateHref(TRUNKSHOW_PATHS.TRUNKSHOW_SLUG)(params, parameterize);
    },
    component: renderWithStatusCode(TrunkshowPage),
    exact: true,
    pagetype: PageType.Trunkshow
  },
  {
    key: 'LookviewPage',
    path: `/:vertical(${ROUTE_VERTICALS})/collection/:trunkshowSlug/looks/:lookId`,
    component: renderWithStatusCode(LookviewContainer),
    exact: true,
    pagetype: PageType.Trunkshow
  },
  {
    key: 'TrunkshowIndexPage',
    path: [TRUNKSHOW_INDEX_PATHS.DESIGNERS, TRUNKSHOW_INDEX_PATHS.PIECES],
    generateHref: (params, parameterize) =>
      params?.mode === 'designers'
        ? generateHref(TRUNKSHOW_INDEX_PATHS.DESIGNERS)(params, parameterize)
        : generateHref(TRUNKSHOW_INDEX_PATHS.PIECES)(params, parameterize),
    component: renderWithStatusCode(TrunkshowIndexPage),
    exact: true,
    pagetype: PageType.TrunkshowListing
  },
  {
    key: 'GuidePage',
    path: `/shop/:guideSlug+`,
    generateHref: params => `/shop/${params?.guideSlug}`,
    component: renderWithStatusCode(GuidePage),
    exact: true,
    pagetype: PageType.Guide
  },
  {
    key: 'EmployeePage',
    path: '/employees',
    component: renderWithStatusCode(EmployeePage),
    exact: true,
    pagetype: PageType.Employee
  },
  {
    key: 'DesignerPage',
    path: [
      '/designer/:designerSlug',
      `/:vertical(${ROUTE_VERTICALS})/designer/:designerSlug`,
      `/:vertical(${ROUTE_VERTICALS})/products/designer/:designerSlug/:category(${ROUTE_CATEGORIES})/:subcategory?/:attributes?`
    ],
    component: renderWithStatusCode(DesignerPage),
    exact: true,
    pagetype: PageType.Designers
  },
  {
    key: 'ShoppingBagPage',
    path: '/shopping-bag',
    component: renderWithStatusCode(ShoppingBagPage),
    exact: true,
    pagetype: PageType.ShoppingBag
  },
  {
    key: 'CheckoutPage',
    path: '/checkout',
    component: renderWithStatusCode(CheckoutPage),
    exact: true,
    pagetype: PageType.Checkout
  },
  {
    key: 'OrderConfirmationPage',
    path: '/success/:orderNumber',
    component: renderWithStatusCode(OrderConfirmationPage),
    exact: true,
    pagetype: PageType.Checkout
  },
  {
    key: 'UnsubscribePage',
    path: '/unsubscribe',
    component: renderWithStatusCode(UnsubscribePage),
    exact: true,
    pagetype: PageType.Account
  },
  {
    key: 'TermsAndConditionsPage',
    path: '/terms',
    component: renderWithStatusCode(TermsAndConditionsPage),
    exact: true,
    pagetype: PageType.Legal
  },
  {
    key: 'LegalPage',
    path: '/legal',
    component: renderWithStatusCode(LegalPage),
    exact: true,
    pagetype: PageType.Legal
  },
  {
    key: 'LegalBrandGuideLinesPage',
    path: '/legal-trademarks',
    component: renderWithStatusCode(LegalTradeMarksPage),
    exact: true,
    pagetype: PageType.Legal
  },
  {
    key: 'LegalTradeMarksPage',
    path: '/legal-brandguidelines',
    component: renderWithStatusCode(LegalBrandGuideLinesPage),
    exact: true,
    pagetype: PageType.Legal
  },
  {
    key: 'TrunkshowReferAFriendTermsPage',
    path: '/trunkshow-refer-a-friend-terms',
    component: renderWithStatusCode(TrunkshowReferAFriendTermsPage),
    exact: true,
    pagetype: PageType.Legal
  },
  {
    key: 'PrivacyPolicyPage',
    path: '/privacy',
    component: renderWithStatusCode(PrivacyPolicyPage),
    exact: true,
    pagetype: PageType.Legal
  },
  {
    key: 'RewardsPage',
    path: '/rewards',
    component: renderWithStatusCode(RewardsPage),
    exact: true,
    pagetype: PageType.Rewards
  },
  {
    key: 'AboutPage',
    path: '/about',
    component: renderWithStatusCode(AboutPage),
    exact: true,
    pagetype: PageType.About
  },
  {
    key: 'ModaPrivate',
    path: '/moda-private',
    component: renderWithStatusCode(ModaPrivatePage),
    exact: true,
    pagetype: PageType.ModaPrivate
  },
  {
    key: 'ModaNetwork',
    path: '/moda-network',
    component: renderWithStatusCode(ModaNetworkPage),
    exact: true,
    pagetype: PageType.ModaNetwork
  },
  {
    key: 'OrderDetailPage',
    path: `/account/orders/:orderNumber/:state(${values(AccountOrderDetailPageState).join('|')})?`,
    component: renderWithStatusCode(OrderDetailPage),
    exact: true,
    pagetype: PageType.Checkout
  },
  {
    key: 'ReturnSummaryPage',
    path: '/account/returns/:shipmentNumber',
    component: renderWithStatusCode(ReturnSummaryPage),
    exact: true,
    pagetype: PageType.Account
  },
  {
    key: 'ReturnConfirmationPage',
    path: '/account/successful-return/:shipmentNumber',
    component: renderWithStatusCode(ReturnConfirmationPage),
    exact: true,
    pagetype: PageType.Account
  },
  {
    key: 'RequestReturnPage',
    path: '/request-return/:orderNumber?',
    component: renderWithStatusCode(RequestReturnPage),
    exact: true,
    pagetype: PageType.RequestReturnPage
  },
  {
    key: 'CancelRequestPage',
    path: '/cancel-request/:orderNumber?',
    component: renderWithStatusCode(CancelRequestPage),
    exact: true,
    pagetype: PageType.CancelRequestPage
  },
  {
    key: 'CancelConfirmationPage',
    path: '/successful-cancel/:orderNumber',
    component: renderWithStatusCode(CancelConfirmationPage),
    exact: true,
    pagetype: PageType.CancelConfirmationPage
  },
  {
    key: 'PrivacyEventPhotography',
    path: '/privacy-eventphotography',
    component: renderWithStatusCode(PrivacyEventPhotography),
    exact: true,
    pagetype: PageType.Legal
  },
  {
    key: 'PrivacyApplicantCandidatePage',
    path: '/privacy-applicant-candidate',
    component: renderWithStatusCode(PrivacyApplicantCandidatePage),
    exact: true,
    pagetype: PageType.Legal
  },
  {
    key: 'OptionsPage',
    path: '/options',
    component: renderWithStatusCode(OptionsPage),
    exact: true,
    pagetype: PageType.Legal
  },
  ...ACCOUNT_ROUTES
];

export type HrefFor = Record<PageName, GenerateHref>;

export const hrefFor: HrefFor = ROUTES.reduce(
  (acc, route) => ({ ...acc, [route.key]: route.generateHref || generateHref(route.path) }),
  {}
) as HrefFor;

export type PathFor = Record<PageName, string>;

export const pathFor: PathFor = ROUTES.reduce(
  (acc, route) => ({ ...acc, [route.key]: route.path }),
  {}
) as PathFor;

export const findRouteFor = (url: string) => ROUTES.find(route => matchPath(url, route));

export const findRouteKeyFor = (url: string) => findRouteFor(url)?.key;

export const PAGE_ROUTES = ROUTES.map(({ key, path, component, ...rest }) => (
  <Route key={key} path={path} component={component} {...rest} />
));
