import { gql } from '@apollo/client';

export const localTypeDefs = gql`
  type User {
    affiliate: Int
    accessToken: String
    clientTier: Int
    countryCode: String
    email: String
    firstName: String
    hasPassword: Boolean
    id: String
    isAmazonConnected: Boolean
    isFacebookConnected: Boolean
    isLoggedIn: Boolean!
    isMember: Boolean
    lastName: String
    phone: String
    statusLevel: Int
    twoFactorAuthTextMessageEnabled: Boolean
    twoFactorAuthAppEnabled: Boolean
    userLoyaltyStatus: Int
  }

  type Cookies {
    attSignedUpWith: String
    cartId: String
    cloudfrontViewerCountry: String
    fullUser: String!
    isAmexCustomer: String
    modaAnonymousId: String
    preferences: String!
    promoCode: String
    sessionQueryParams: String
    signifydSessionId: String
    stateAbbreviation: String!
    stateName: String!
    zipCode: String!
  }

  type ErrorModal {
    show: Boolean!
    message: String
  }

  type PaymentError {
    error: String
  }

  type Notification {
    id: String!
    type: String!
    message: String!
  }

  extend type Query {
    cookies: Cookies
    user: User
    errorModal: ErrorModal
    paymentError: PaymentError
    notifications: [Notification!]!
  }
`;
