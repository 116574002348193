import { Clickable, ControlLink, Divider, Stack, Text } from '@moda/om';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ExpandIcon from '@moda/icons/expand-12';
import ChevronRightIcon from '@moda/icons/chevron-right-12';
import HideIcon from '@moda/icons/hide-12';
import { titleize } from '@moda/portal-stanchions';
import { flatten, map } from 'ramda';
import { MobileNavV2SubcategoriesSection } from '../MobileNavV2SubcategoriesSection';
import { MobileNavV2EditorialsSection } from '../MobileNavV2EditorialsSection';
import { useMobileNavigation } from '../../../hooks/useNavigation';

import './MobileNavV2Tab.scss';

interface Props {
  selectedTabIndex: number;
  onClose: () => void;
}

export const MobileNavV2Tab: React.FC<Props> = ({ selectedTabIndex, onClose }) => {
  const [expandedCategory, setExpandedCategory] = useState<number>();
  const { data } = useMobileNavigation();

  const content = data?.verticals[selectedTabIndex];

  const units = useMemo(() => {
    if (!content) return [];

    return flatten(map(({ units }) => units, content.items));
  }, [content]);

  const categories = useMemo(
    () => units.filter(unit => unit.__typename === 'ListNavigatorUnit'),
    [units]
  );

  const editorials = useMemo(
    () => units.filter(unit => unit.__typename === 'EditorialNavigatorUnit'),
    [units]
  );

  const handleExpand = useCallback(
    (index: number) => {
      if (expandedCategory === index) {
        setExpandedCategory(undefined);
        return;
      }

      setExpandedCategory(index);
    },
    [expandedCategory]
  );

  const expandedCategoryContent = useMemo(() => {
    if (expandedCategory === undefined) return;

    const currentSelectedCategory = categories[expandedCategory];

    if (!currentSelectedCategory) return;

    return currentSelectedCategory.subcategories;
  }, [categories, expandedCategory]);

  useEffect(() => setExpandedCategory(undefined), [selectedTabIndex]);

  if (!content) return null;

  return (
    <Stack className="MobileNavV2Tab" space={4}>
      <Stack direction="horizontal" justifyContent="space-between" space={1}>
        <ControlLink
          className="MobileNavV2Tab__header"
          href={`/${content?.title}`}
          underlined={false}
        >
          {content.title === 'HOME' ? 'Home' : `${titleize(content.title)} Home`}
        </ControlLink>

        <div className="MobileNavV2Tab__chevron-right-icon">
          <ChevronRightIcon />
        </div>
      </Stack>

      <Divider />

      {categories.map((category, index) => {
        if (category.header === '') {
          return (
            <div key={`--${index}`}>
              <MobileNavV2SubcategoriesSection
                className="MobileNavV2Tab__no-header-subcategories"
                data={category.subcategories}
                onClose={onClose}
              />
            </div>
          );
        }

        return (
          <div key={`${category.header} -- ${index}`}>
            <Clickable className="MobileNavV2Tab__category" onClick={() => handleExpand(index)}>
              <Stack space={2}>
                <Stack
                  className="MobileNavV2Tab__category-name"
                  direction="horizontal"
                  justifyContent="space-between"
                  space={1}
                >
                  <Text>{category.header}</Text>

                  <div className="MobileNavV2Tab__plus-minus-icon">
                    {expandedCategory !== index && <ExpandIcon />}

                    {expandedCategory === index && <HideIcon />}
                  </div>
                </Stack>
              </Stack>
            </Clickable>
            {expandedCategory === index && (
              <MobileNavV2SubcategoriesSection data={expandedCategoryContent} onClose={onClose} />
            )}
          </div>
        );
      })}

      {editorials && (
        <>
          <Divider />

          <MobileNavV2EditorialsSection editorials={editorials} />
        </>
      )}

      <Divider />
    </Stack>
  );
};
