import { useEffect, useRef } from 'react';
import { AuthAction } from '../../lib/authenticate';
import { loadRecaptchaV2Script, loadRecaptchaV3Script } from '../../lib/loadScript';
import { CONFIG } from '../../config';

import './recaptcha.scss';

type RecaptchaAction = 'register' | 'login';
type RecaptchaActions = {
  [TAction in AuthAction]: RecaptchaAction;
};

export const RECAPTCHA_ACTIONS: RecaptchaActions = {
  REGISTER: 'register',
  'SIGN IN': 'login'
};

const handleRecaptchaV2 = () => {
  if (!window.grecaptcha) {
    return Promise.resolve('');
  }

  window.grecaptcha.execute();

  const intervalTime = 100;
  const interval = setInterval(() => {
    const challengeFrame = document.querySelector('iframe[title="recaptcha challenge"]');

    // istanbul ignore else
    if (challengeFrame && challengeFrame.parentElement) {
      challengeFrame.parentElement.classList.add('recaptcha-challenge-container');
      clearInterval(interval);
    }
  }, intervalTime);

  return new Promise<string>(resolve => {
    window.onResolveRecaptchaV2 = (token: string) => {
      clearInterval(interval);
      resolve(token);
    };
  });
};

const handleRecaptchaV3 = (action: string): Promise<string> => {
  if (!window.grecaptcha) {
    return Promise.resolve('');
  }

  return new Promise(resolve =>
    window.grecaptcha.execute(CONFIG.RECAPTCHA_V3_KEY, { action }).then(resolve)
  );
};

export const useRecaptcha = () => {
  useEffect(() => {
    loadRecaptchaV2Script();
    loadRecaptchaV3Script();
  }, []);

  return { handleRecaptchaV2, handleRecaptchaV3 };
};

export const useRenderedRecaptchaV2 = (id: string, callback: () => void) => {
  const recaptchaRendered = useRef(false);
  const INTERVAL_DURATION = 50;
  useEffect(() => {
    loadRecaptchaV2Script();
    const intervalId = setInterval(() => {
      if (window.grecaptcha && !recaptchaRendered.current) {
        window.grecaptcha.render(id, {
          callback: () => callback(),
          sitekey: CONFIG.RECAPTCHA_V2_KEY
        });
        recaptchaRendered.current = true;

        clearInterval(intervalId);
      }
    }, INTERVAL_DURATION);

    return () => clearInterval(intervalId);
  }, [id, callback]);
};
