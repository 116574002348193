import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { ListNavigatorUnitFragment } from '../../../generated/types';
import { tc } from '../../../lib/trackingContext';
import { useDesktopNavigationV2 } from '../useDesktopNavigationV2';

import './DesktopNavigationV2ListColumnV2.scss';

interface Props {
  className?: string;
  parentLabel: string;
  content: ListNavigatorUnitFragment;
}

export const DesktopNavigationV2ListColumnV2: React.FC<Props> = ({
  className,
  parentLabel,
  content
}) => {
  const { selectedVerticalData } = useDesktopNavigationV2();

  return (
    <div className={classNames('DesktopNavigationV2ListColumnV2', className)}>
      <h4 className="DesktopNavigationV2ListColumnV2__header">{content.header}</h4>

      {content.subcategories.map((subcategory, index) =>
        subcategory.href === '' ? (
          <h4
            key={subcategory.title + index}
            className="DesktopNavigationV2ListColumnV2__header-secondary"
          >
            {subcategory.title}
          </h4>
        ) : (
          <Link
            className="DesktopNavigationV2ListColumnV2__link"
            key={subcategory.href + index}
            to={subcategory.href}
            onClick={event =>
              tc.track('Navigation Item Clicked', {
                addToTrackingEvent: {
                  url: subcategory.href,
                  text: subcategory.title,
                  label: subcategory.title,
                  hierarchy: `${selectedVerticalData?.title}/${parentLabel}/${subcategory.title}`,
                  coordinateX: event?.pageX || 0,
                  coordinateY: event?.pageY || 0
                }
              })
            }
          >
            {subcategory.title}
          </Link>
        )
      )}
    </div>
  );
};
