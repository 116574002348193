import { gql } from '@apollo/client';
import React, { useCallback } from 'react';
import { MobileSiteNavMenuPaneFragment } from '../../generated/types';
import { MobileSiteNavOption } from './MobileSiteNavOption';
import { MobileSiteNavMenuPaneBack } from './MobileSiteNavMenuPaneBack';
import { SelectOptions } from './MobileSiteNavMenu';

import './MobileSiteNavMenuPane.scss';

type NavItem = NonNullable<MobileSiteNavMenuPaneFragment['options']>[number];

type MobileNavColumn = NavItem['options'][number];

type LinkItem = NonNullable<
  MobileNavColumn['options'][number]['options'][number]['options'][number]
>;

export const MOBILE_SITE_NAV_MENU_PANE_FRAGMENT = gql`
  fragment MobileSiteNavMenuPaneFragment on DisplayPage {
    options: navItems {
      label
      options: mobileChildren {
        options: lists {
          label
          options: items {
            url
            label
            options: items {
              url
              label
            }
          }
        }
      }
    }
  }
`;

export type Option = NavItem | MobileNavColumn | LinkItem | null;
export type Options = NavItem[] | MobileNavColumn[] | (LinkItem | null)[];

interface Props {
  options: SelectOptions;
  onSelect({ label, options }: SelectOptions): void;
  onClose(): void;
}

const everyIsType =
  (typename: 'NavItem' | 'MobileNavColumn' | 'LinkItem' | undefined) => (options: Options) =>
    (options as NavItem[]).every(option => option?.__typename === typename);

const isNavItems = (options: Options): options is NavItem[] => everyIsType('NavItem')(options);

const isMobileNavColumns = (options: Options): options is MobileNavColumn[] =>
  everyIsType('MobileNavColumn')(options);

const isLinkItems = (options: Options): options is (LinkItem | null)[] =>
  everyIsType('LinkItem')(options);

const MobileSiteNavMenuPaneOptions: React.FC<Props> = ({ options, onSelect, onClose }) => {
  const handleClick = useCallback(
    ({ label, options }: SelectOptions) =>
      () =>
        onSelect({ label, options }),
    [onSelect]
  );

  // Top-level pane
  if (isNavItems(options.options)) {
    return (
      <>
        {options.options.map(
          (option, index) =>
            option.label && (
              <MobileSiteNavOption
                key={index}
                title={option.label}
                {...(option.options.length > 0
                  ? // Selects pane
                    { arrow: true, onClick: handleClick(option) }
                  : // Is link to page
                    { onClick: onClose })}
              >
                {option.label}
              </MobileSiteNavOption>
            )
        )}
      </>
    );
  }

  // Second-level pane
  if (isMobileNavColumns(options.options)) {
    return (
      <>
        {options.options.map((option, index1) => (
          <React.Fragment key={index1}>
            {option.options.map((option, index2) => (
              <React.Fragment key={index2}>
                {option.label && options.label?.toLowerCase() !== option.label.toLowerCase() && (
                  <h3 className="MobileSiteNavMenuPane__header">{option.label}</h3>
                )}

                {option.options.map(
                  (option, index3) =>
                    option.label && (
                      <MobileSiteNavOption
                        key={index3}
                        title={option.label}
                        {...(option.options && option.options.length > 0
                          ? // Selects pane
                            { arrow: true, onClick: handleClick(option) }
                          : // Is link to page
                            { href: option.url, onClick: onClose })}
                      >
                        {option.label}
                      </MobileSiteNavOption>
                    )
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </>
    );
  }

  // Third-level pane
  if (isLinkItems(options.options)) {
    return (
      <>
        {options.options.map(
          (option, index) =>
            option &&
            option.label && (
              <MobileSiteNavOption
                key={index}
                title={option.label}
                href={option.url}
                onClick={onClose}
              >
                {option.label}
              </MobileSiteNavOption>
            )
        )}
      </>
    );
  }

  return null;
};

export const MobileSiteNavMenuPane: React.FC<{ onBack(): void } & Props> = ({
  options,
  onSelect,
  onBack,
  onClose
}) => {
  if (!options) return null;

  return (
    <div className="MobileSiteNavMenuPane">
      {options.label && (
        <MobileSiteNavMenuPaneBack onClick={onBack}>{options.label}</MobileSiteNavMenuPaneBack>
      )}

      <MobileSiteNavMenuPaneOptions options={options} onSelect={onSelect} onClose={onClose} />
    </div>
  );
};
