import React from 'react';
import { gql, TypedDocumentNode } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { hrefFor } from '../../routers';
import { PageLayoutQuery, PageLayoutQueryVariables } from '../../generated/types';
import { logger } from '../../lib/logger';
import { usePreserveUtmQueryParam } from '../../hooks/usePreserveUtmQueryParam';
import { useSessionQueryParams } from '../../hooks/useSessionQueryParams';
import { usePageTracking } from '../../hooks/usePageTracking';
import { useSignifydSessionId } from '../../hooks/useSignifydSessionId';
import { usePreservePromoCodeInCookie } from '../../hooks/usePreservePromoCodeInCookie';
import { useSkippableQuery } from '../../hooks/useSkippableQuery';
import { EnableLazyLoad } from '../LazyLoad';
import { useCountry } from '../CountrySelector';
import { ErrorBoundary } from '../ErrorBoundary';
import { SitePromoBanner, SITE_PROMO_BANNER_FRAGMENT } from '../SitePromoBanner';
import { SiteNav, SITE_NAV_FRAGMENT } from '../SiteNav';
import { MinimalNav } from '../MinimalNav';
import { Notifications } from '../Notifications';
import { SiteFooter } from '../SiteFooter';
import { EmailSignup } from '../EmailSignup';
import { SignifydScript } from '../SignifydScript';
import { useDesktopNavigation } from '../../hooks/useNavigation';

import './PageLayout.scss';

export const PAGE_LAYOUT_QUERY: TypedDocumentNode<PageLayoutQuery, PageLayoutQueryVariables> = gql`
  query PageLayoutQuery(
    $gender: String!
    $slug: String!
    $countryCode: String!
  ) {
    displayPage(
      gender: $gender
      slug: $slug
      country_code: $countryCode
    ) {
      ...SitePromoBannerFragment
      ...SiteNavFragment
    }
  }
  ${SITE_NAV_FRAGMENT}
  ${SITE_PROMO_BANNER_FRAGMENT},
`;

export const PageLayout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const {
    country: { alpha2Code: countryCode }
  } = useCountry();
  const { pathname } = useLocation();
  const includeHeader = pathname !== hrefFor.CheckoutPage();

  const { data: pageLayoutQueryData, error: pageLayoutQueryError } = useSkippableQuery<
    PageLayoutQuery,
    PageLayoutQueryVariables
  >(PAGE_LAYOUT_QUERY, {
    variables: {
      gender: 'women',
      slug: 'women',
      countryCode
    },
    skip: !includeHeader
  });

  const {
    navigation: { data: desktopNavigatorQueryData, error: desktopNavigatorQueryError }
  } = useDesktopNavigation();

  if (pageLayoutQueryError) {
    // Allow page to render as best it can and warn the error
    logger.warn('PAGE_LAYOUT', pageLayoutQueryError.message, pageLayoutQueryError);
  }

  if (desktopNavigatorQueryError) {
    // Allow page to render as best it can and warn the error
    logger.warn('PAGE_LAYOUT', desktopNavigatorQueryError.message, desktopNavigatorQueryError);
  }

  usePreserveUtmQueryParam();
  useSessionQueryParams();
  usePageTracking();
  useSignifydSessionId({ createIfNotExists: true });
  usePreservePromoCodeInCookie();

  return (
    <div className="PageLayout">
      {includeHeader && (
        <ErrorBoundary FallbackComponent={MinimalNav}>
          <SitePromoBanner displayPage={pageLayoutQueryData?.displayPage} />

          <EnableLazyLoad>
            <SiteNav
              displayPage={pageLayoutQueryData?.displayPage}
              desktopNavigator={desktopNavigatorQueryData}
            />
          </EnableLazyLoad>
        </ErrorBoundary>
      )}

      <EmailSignup />
      <Notifications />
      <SignifydScript />

      <main className="PageLayout__content">{children}</main>

      <SiteFooter />
    </div>
  );
};
